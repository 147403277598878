import React, { useEffect, useState } from 'react';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import { logEvent } from '../../analytics';
import { useUser } from '../UserContext';
import { Oval } from 'react-loader-spinner';
import { Helmet } from 'react-helmet';

import './index.css';

const Login = () => {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [acceptedTerms, setAcceptedTerms] = useState(true);
  const { nizhalUser,setNizhalUser } = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (nizhalUser) {
      navigate(-1); 
    }
  }, [nizhalUser, navigate]);


  const handleLoginSuccess = async (response) => {
    if (!acceptedTerms) {
      alert('Please accept the privacy policy and terms and conditions.');
      return;
    }

    setIsLoading(true);
    try {
      const { credential } = response;
      const googleResponse = await axios.post(
        'https://2mn4dxxw3hj2yrhqzbsxdyirva0uksoy.lambda-url.ap-south-1.on.aws/auth/google/callback',
        { token: credential },
        { withCredentials: true }
      );

      const userData = googleResponse.data;

      const storedUser = {
        userName: userData.displayName,
        userImageUrl: userData.profileImageUrl,
        userId: userData.uniqueId,
        userEmail: userData.email,
        userPlan: userData.plan, 
        userCount: userData.count,
      };

      setUser(storedUser);
      setNizhalUser(storedUser); 


      logEvent('User', 'Logged In', userData.email);

      setIsLoading(false);
      navigate(-1);
    } catch (error) {
      console.error('Login failed', error);
      setIsLoading(false);
    }
  };

  const handleLoginFailure = (response) => {
    console.error('Login failed', response);
  };


  return (
    <GoogleOAuthProvider clientId="402163496970-qhqqclg0u2s6g2hro85fie1cnli4n8cv.apps.googleusercontent.com">
      <div className={`container`}>
      <Helmet>
    <title>Login - NizhalTnpsc</title>
    <meta
        name="description"
        content="Login to your NizhalTnpsc account to access personalized TNPSC answer evaluations, mentorship programs, and more."
    />
    <meta
        name="keywords"
        content="NizhalTnpsc login, TNPSC Exam login, answer review portal login,TNPSC  answer writing login"
    />
    <meta
        property="og:title"
        content="Login - NizhalTnpsc"
    />
    <meta
        property="og:url"
        content="https://nizhaltnpsc.com/login"
    />
    <meta
        property="og:description"
        content="Access your NizhalTnpsc account to get instant updates and expert reviews of your TNPSC answers."
    />
    <meta property="og:site_name" content="NIZHAL TNPSC" />
    <script type="application/ld+json">
                {`
                {
                  "@context": "https://schema.org",
                  "@type": "WebPage",
                  "name": "Login - NizhalTnpsc",
                  "description": "Login to your NizhalTnpsc account to access personalized TNPSC answer evaluations, mentorship programs, and more.",
                  "url": "https://nizhaltnpsc.com/login"
                }
                `}
            </script>
</Helmet>

        <div className="forms-container">
          <div className="signin-signup">
          
            <form className="sign-in-form">
              <h2 className="title">Login with Google</h2>
              <div className="login-container">
                <div className="login-box">
                  {isLoading ? (
                    <div className="loader-container">
                      <Oval height={40} width={40} color="#4fa94d" />
                    </div>
                  ) : (
                    !user && (
                      <>
                      <GoogleLogin
                          onSuccess={handleLoginSuccess}
                          onFailure={handleLoginFailure}
                          cookiePolicy={'single_host_origin'}
                          render={(renderProps) => (
                            <button
                              className="google-login-btn"
                              onClick={renderProps.onClick}
                              disabled={renderProps.disabled}

                            >
                              <img
                                src="https://developers.google.com/identity/images/g-logo.png"
                                alt="Google Logo"
                                style={{ marginRight: '10px' }}
                              />
                              Sign in with Google
                            </button>
                          )}
                        />
                        <div className="terms-container">
                          <input
                            type="checkbox"
                            id="accept-terms"
                            checked={acceptedTerms}
                            onChange={(e) => setAcceptedTerms(e.target.checked)}
                          />
                          <label htmlFor="accept-terms">
                            I accept the{' '}
                            <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a> and{' '}
                            <a href="/terms-and-conditions" target="_blank" rel="noopener noreferrer">Terms and Conditions</a>.
                          </label>
                        </div>
                        
                      </>
                    )
                  )}
                </div>
              </div>
            </form>
          </div>
        <div className="panels-container">
          <div className="panel left-panel">
            <div className="content">
              <h3>Nizhal Academy</h3>
              <p>
                At Nizhal TNPSC, we are dedicated to helping you excel in your TNPSC exams with comprehensive resources and interactive tools. Our platform offers a range of features designed to support your preparation and ensure you are well-prepared for every challenge.
              </p>
            </div>
          </div>
        </div>
        </div>

      
      </div>
    </GoogleOAuthProvider>
  );
};

export default Login;
