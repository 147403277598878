import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FaTimes } from 'react-icons/fa';
import { logEvent } from '../../analytics';
import './index.css'; 

const LoginRequiredModal = ({ onClose }) => {
  const navigate = useNavigate();

  const handleLoginClick = () => {
    navigate('/login'); 
    logEvent('User', 'Logged In', 'Logged in clicked from Login Notification Modal');

  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="close-btn" onClick={onClose}>
          <FaTimes />
        </button>
        <img src="/assets/logoTNPSC.jpg" alt="Nizhal TNPSC Logo" className="login-modal-logo-img" />
        <h2>Login Required</h2>
        <p>You need to log in to access the feature.</p>
        <button className="login-required-btn" onClick={handleLoginClick}>
          Go to Login
        </button>
      </div>
    </div>
  );
};

export default LoginRequiredModal;
