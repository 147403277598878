import React from 'react';
import {Link} from 'react-router-dom';
import { FaClipboardList, FaClock, FaHeadset, FaBook, FaTools, FaDollarSign } from 'react-icons/fa';
import './index.css';

function FeatureSection() {
  return (
    <div className="feat bg-gray pt-5 pb-5">
      <div className="why-container">
        <div className="why-choose-us-container-n-a">
          <div className="section-head col-sm-12">
            <h4><span>Why Choose</span> Us?</h4>
            <p className="why-choose-us-para">When you choose our MCQ website, you'll benefit from years of experience in providing comprehensive and affordable educational resources. We understand the needs of students and are dedicated to offering top-quality materials and support.</p>
          </div>
          <div className="why-choose-us-card-container-n-a">
          <Link to="/plans">
            <div className="why-choose-us-card-n-a">
              <span className="icon feature_box_col_six"><FaDollarSign /></span>
              <h3>Affordable Pricing</h3>
              <p>We offer all these features at an affordable cost, ensuring you get the best value for your investment in education.</p>
                <button className="card-button-nizhal-admy">Plans</button>
            </div>
            </Link>
          <Link to="/mcq">
            <div className="why-choose-us-card-n-a">
              <span className="icon feature_box_col_one"><FaClipboardList /></span>
              <h3>Comprehensive Tests</h3>
              <p>We offer a wide range of MCQ tests, including unit-wise and subject-wise tests, to cover all your study needs.</p>
              <button className="card-button-nizhal-admy">MCQ</button>
            </div>
            </Link>
            <Link to="/weekly-test" >
            <div className="why-choose-us-card-n-a">
              <span className="icon feature_box_col_two"><FaClock /></span>
              <h3>Weekly Mock Tests</h3>
              <p>Our weekly mock tests help you stay on track and assess your progress regularly.</p>
              <button className="card-button-nizhal-admy">Mock Test</button>
            </div>
            </Link>
            <Link to="/email">
            <div className="why-choose-us-card-n-a">
              <span className="icon feature_box_col_three"><FaHeadset /></span>
              <h3>24 x 7 User Support</h3>
              <p>We are here to support you at any time. Our dedicated team is available 24/7 to assist with any queries.</p>
              <button className="card-button-nizhal-admy">Get Support</button>
            </div>
            </Link>
            <Link to="/materials">
            <div className="why-choose-us-card-n-a">
              <span className="icon feature_box_col_four"><FaBook /></span>
              <h3>Extensive Study Materials</h3>
              <p>Access a vast collection of study materials to help you prepare thoroughly for your exams.</p>
              <button className="card-button-nizhal-admy">Materials</button>
            </div>
            </Link>
            <Link to="/">
            <div className="why-choose-us-card-n-a">
              <span className="icon feature_box_col_five"><FaTools /></span>
              <h3>Advanced Features</h3>
              <p>Our platform includes advanced features designed to enhance your learning experience, such as performance tracking.</p>
              <button className="card-button-nizhal-admy">Read More</button>
            </div>
            </Link>
            
        </div>
        </div>
      </div>
    </div>
  );
}

export default FeatureSection;
