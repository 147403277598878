import React from 'react';
import './index.css';
import { useTest } from '../TestContext';
import { Helmet } from 'react-helmet';
import { logEvent } from '../../analytics';
import { Link } from 'react-router-dom';

function MCQ() {
  const { setTopic } = useTest();
  const topics = [
    { name: 'Tamil', description: ["3000+ questions", "Easy to understand", "100% accuracy", "Easy to use", "Easy to remember"], imageLink: "assets/tamil-image.jpg" },
    { name: 'General Studies', description: ["2000+ questions", "Easy to understand", "100% accuracy", "Easy to use", "Easy to remember"], imageLink: "assets/general-studies.jpg" },
    { name: 'Aptitude', description: ["1000+ questions", "Easy to understand", "100% accuracy", "Easy to use", "Easy to remember"], imageLink: "assets/mentalAptitude-image.webp" },
  ];

  const handleTopicClick = (topic) => {
    setTopic(topic.name);
    logEvent('MCQ', 'Clicked Topic', topic.name);
  };

  return (
    <div className="mcq-container">
      <Helmet>
    <title>TNPSC MCQ Test - NizhalTnpsc</title>
    <meta
        name="description"
        content="Attempt TNPSC MCQ tests on NizhalTnpsc to practice and prepare effectively for your exams with topic-wise questions."
    />
    <meta
        name="keywords"
        content="TNPSC MCQ test, NizhalTnpsc, TNPSC exam preparation, topic-wise TNPSC questions, TNPSC practice tests"
    />
    <meta
        property="og:title"
        content="TNPSC MCQ Test - NizhalTnpsc"
    />
    <meta
        property="og:url"
        content="https://nizhaltnpsc.com/mcq"
    />
    <meta
        property="og:description"
        content="Prepare for your TNPSC exams with our comprehensive MCQ tests. Practice topic-wise questions and get ready for your exams with NizhalTnpsc."
    />
    <meta property="og:site_name" content="NIZHAL TNPSC" />
</Helmet>
      <h1>SELECT YOUR TOPIC</h1>
      <div className="row">
        {topics.map((topic, index) => (
          <Link
            to={`/mcq/${topic.name.toLowerCase().replace(' ', '-')}`}
            key={index}
            className={`card card-${index}`}
            onClick={() => handleTopicClick(topic)}
          >
            <h2 className={`card-head card-head-${index}`}>{topic.name}</h2>
            <img src={topic.imageLink} alt="${topic.name} picture" className='image-mcq-n-a' />
            <div className={`card-paragraph card-paragraph-${index}`}>
              <ul className="description-list">
                {topic.description.map((desc, descIndex) => (
                  <li key={descIndex}>
                    <span className="bullet-point">&#x1F4A1;</span>
                    <span className="description-text">{desc}</span>
                  </li>
                ))}
              </ul>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default MCQ;
