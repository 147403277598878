import React from "react";
import { Link } from 'react-router-dom';

import "./index.css";

export default function Component() {
  return (
    <>
      <section className="dw-course-section">
        <div className="dw-container-fluid">
          <div className="dw-section-title">
            <h2 className="dw-title">Our Regular Courses</h2>
          </div>
          <div className="dw-row">
            <Link to="/mcq" className="dw-col">
              <div className="dw-course-card">
                <div className="dw-course-img">
                  <img
                    alt="regular"
                    src="/assets/Daily Quiz-100.jpg"
                    className="dw-img"
                  />
                </div>
                <div className="dw-course-content">
                  <h5 className="dw-course-title">Tamil, General Studies, Aptitude</h5>
                  <h3 className="dw-course-text">Both Tamil & English Medium</h3>
                  <button className="dw-border-btn">Start Test</button>
                </div>
              </div>
            </Link>
            <Link to="/weekly-test" className="dw-col">
              <div className="dw-course-card">
                <div className="dw-course-img">
                  <img
                    alt="regular"
                    src="/assets/weekly-test-second.jpg"
                    className="dw-img"
                  />
                </div>
                <div className="dw-course-content">
                  <h5 className="dw-course-title">200 Questions Mock Test</h5>
                  <h3 className="dw-course-text">Both Tamil & English Medium</h3>
                  <button className="dw-border-btn">Start Weekly Test</button>
                </div>
              </div>
            </Link>
            <Link to="/materials" className="dw-col">
              <div className="dw-course-card">
                <div className="dw-course-img">
                  <img
                    alt="regular"
                    src="/assets/school-book-material.jpg"
                    className="dw-img"
                  />
                </div>
                <div className="dw-course-content">
                  <h5 className="dw-course-title">6th - 12th Book Materials</h5>
                  <h3 className="dw-course-text">Both Tamil & English Medium</h3>
                  <button className="dw-border-btn">Start Reading</button>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </section>
    </>
  );
}
