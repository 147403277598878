import React from 'react';
import { Link } from 'react-router-dom';
import './index.css';
import { logEvent } from '../../analytics';

const materials = [
  { grade: 6, pdfUrl: 'https://dropbox.com/scl/fi/nywdlqoollxi5idha5dxa/6th_Tamil_Term_I-www.tntextbooks.in.pdf?rlkey=g0lgdkmdhnyvxc5wq0pvy9eq5&st=x9o5ddp1&dl=1' },
  { grade: 7 },
  { grade: 8 },
  { grade: 9 },
  { grade: 10 },
  { grade: 11 },
  { grade: 12 },
];

function Materials() {
  const handleGradeClick = (grade) => {
    logEvent('Button Click', 'Grade Click', grade);
  };

  return (
    <div className="materials-container">
      <h1 className="materials-title">Download Tamil Nadu Text Books</h1>
      <div className="materials-grid">
        {materials.map((material) => (
          <Link 
            to={`/books/${material.grade}`} 
            className="material-link" 
            onClick={() => handleGradeClick(material.grade)} // Track click event
            key={material.grade} // Moved the key here to avoid warnings
          >
            <div className="material-card">
              <h2 className="material-grade">{material.grade}th Books</h2>
              {/* {material.pdfUrl && (
                <a href={material.pdfUrl} download className="download-link">
                  Download PDF
                </a>
              )} */}
            </div>
          </Link>
        ))}
      </div>
      <div className="info-message">
        <p>Other PDFs will be uploaded soon. Stay tuned for more updates!</p>
      </div>
    </div>
  );
}

export default Materials;
