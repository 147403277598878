import React from 'react';
import Modal from 'react-modal';
import { FaTimes } from 'react-icons/fa';
import './index.css';

const TamilTestModal = ({
  isOpen,
  onRequestClose,
  options,
  noOfQuestions,
  setNoOfQuestions,
  handleStartTest
}) => {
  return (
   
<div className="modal-overlay">
<div className="modal-content">
  <button className="close-btn" onClick={onRequestClose}>
    <FaTimes />
  </button>
  <div className="select-container-aptitude-n-a">
          <label htmlFor="question-select" className="custom-select-label">
            Select Number of Questions:
          </label>
          <select
            id="question-select"
            className="custom-select"
            value={noOfQuestions}
            onChange={(e) => setNoOfQuestions(Number(e.target.value))}
          >
            {options.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
        <button className="tamil-start-button" onClick={handleStartTest}>
          Start Test
        </button>
</div>
</div>
  );
};

export default TamilTestModal;

