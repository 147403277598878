import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FaTimes } from 'react-icons/fa';
import { logEvent } from '../../analytics';
import './index.css'; 

const LoginRequiredModal = ({ onClose }) => {
  const navigate = useNavigate();

  const handlePurchaseClick = () => {
    navigate('/plans'); 
    logEvent('Plan', 'Logged In User', 'Purchase plan clicked from notification modal');

  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="close-btn" onClick={onClose}>
          <FaTimes />
        </button>
        <img src="/assets/logoTNPSC.jpg" alt="Nizhal TNPSC Logo" className="login-modal-logo-img" />
        <h2>Access Restricted</h2>
        <p>This test is available only for users with a premium or basic plan. You can also try an MCQ for free.</p>
        <button className="login-required-btn" onClick={handlePurchaseClick}>
          Purchase Plan
        </button>
      </div>
    </div>
  );
};

export default LoginRequiredModal;
