import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FaTimes } from 'react-icons/fa';
import './index.css'; // Import the CSS file for styling

const LoginRequiredModal = ({ onClose }) => {
  const navigate = useNavigate();

  const handlePurchaseClick = () => {
    navigate('/plans'); 
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="close-btn" onClick={onClose}>
          <FaTimes />
        </button>
        <img src="/assets/logoTNPSC.jpg" alt="Nizhal TNPSC Logo" className="login-modal-logo-img" />
        <h2>Limit Exceed</h2>
        <p>Your free limit is exceed please purchase a plan for unlimited test.</p>
        <button className="login-required-btn" onClick={handlePurchaseClick}>
          Purchase Plan
        </button>
      </div>
    </div>
  );
};

export default LoginRequiredModal;
