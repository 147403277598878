import React from 'react';
import './index.css';
import { Link } from 'react-router-dom';
import { FaYoutube, FaTwitter, FaInstagram, FaLinkedin } from 'react-icons/fa';
// import logo from './logo.png'; // Ensure you have a logo image in the specified path

function Footer() {
  return (
    <footer className="na-footer">
      <div className="na-footer-content">
        <div className="na-footer-column">
          <div className="na-footer-logo">
          <img src='\assets\logoTNPSC.jpg' alt="Nizhal TNPSC Logo" className="na-nav-logo-img" />
          </div>
          <div className="na-footer-text">
            <p>Nizhal is your ultimate platform for mastering MCQs, accessing comprehensive study materials, and participating in weekly simulations to enhance your learning experience.</p>
          </div>
        </div>
        <div className="row">
          <div className="na-footer-column">
            <h4>Quick Links</h4>
            <div className="na-footer-links">
              <Link to="/home">Home</Link>
              <Link to="/weekly-test">Weekly Test</Link>
              <Link to="/mcq">MCQ</Link>
              <Link to="/materials">Materials</Link>
              <Link to="/plans">Plans</Link>
            </div>
          </div>
          <div className="na-footer-column">
            <h4>Policies</h4>
            <div className="na-footer-links">
              <Link to="/terms-and-conditions">Terms & Conditions</Link>
              <Link to="/privacy-policy">Privacy Policy</Link>
              <Link to="/refund-policy">Refund Policy</Link>
              <a href="mailto:support@nizhaltnpsc.com">Contact Us</a>
            </div>
          </div>
        </div>
      </div>
      <div className="na-footer-social">
        <a href="https://www.youtube.com/channel/UCiw-KczI2n4G_jw2Jq6-3sA" target="_blank" rel="noopener noreferrer"><FaYoutube /></a>
        {/* <a href="https://twitter.com" target="_blank" rel="noopener noreferrer"><FaTwitter /></a> */}
        <a href="https://www.instagram.com/tnpsc_lifeline_/" target="_blank" rel="noopener noreferrer"><FaInstagram /></a>
        {/* <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer"><FaLinkedin /></a> */}
      </div>
      <div className="na-footer-copy">
        &copy; 2024 Nizhal. All rights reserved.
      </div>
    </footer>
  );
}

export default Footer;
