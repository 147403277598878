import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../UserContext';
import axios from 'axios';
import './index.css'; 

const PaymentSuccess = () => {
  const navigate = useNavigate();
  const { nizhalUser, setNizhalUser } = useUser();
  const [countdown, setCountdown] = useState(5); // Initialize countdown to 5 seconds


    useEffect(() => {
    // Fetch the updated user data when the component mounts
    const fetchUpdatedUserData = async () => {
      if (nizhalUser.userId) {
        try {
          const response = await axios.get(`https://2mn4dxxw3hj2yrhqzbsxdyirva0uksoy.lambda-url.ap-south-1.on.aws/api/user/${nizhalUser.userId}`);
          const userData = response.data;
          const storedUser = {
            userName: userData.displayName,
            userImageUrl: userData.profileImageUrl,
            userId: userData.uniqueId,
            userEmail: userData.email,
            userPlan: userData.plan, 
            userCount: userData.count,
          };
          setNizhalUser(storedUser); 
        } catch (error) {
          console.error('Failed to fetch updated user data', error);
        }
      }
    };

    fetchUpdatedUserData();
  }, []);


  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown(prevCountdown => prevCountdown - 1);
    }, 1000);

    const redirectTimer = setTimeout(() => {
      navigate('/');
    }, 5000);

    return () => {
      clearInterval(timer);
      clearTimeout(redirectTimer);
    };
  }, [navigate]);

  return (
    <div className="payment-success-container">
      <div className="video-container">
        <img src="\assets\greentick-unscreen.gif" alt="Payment Successful" className='payment-success-img' />
      </div>
      <div className="message-container">
        <h1 className="success-message">Payment Successful!</h1>
        <p className="redirect-message">Redirecting to home page in {countdown} seconds...</p>
      </div>
    </div>
  );
};

export default PaymentSuccess;
