import React from 'react';
import './index.css';

const RefundPolicy = () => {
  return (
    <div className="refund-container">
      <h1 className="refund-title">Refund Policy</h1>
      
      <p>Refunds</p>
      <p>
        We strive to provide high-quality services and products. If you are not satisfied with your purchase, please contact us at <a href="mailto:support@nizhaltnpsc.com">support@nizhaltnpsc.com</a> within 2-3 days of your purchase to request a refund.
      </p>
      
      <p>Eligibility for Refunds</p>
      <p>
        To be eligible for a refund, your request must meet the following conditions:
      </p>
      <ul>
        <li>The online tests or materials must not have been accessed or used.</li>
        <li>Refund requests must be made within 7-8 business days of purchase.</li>
      </ul>
      
      <p>Non-refundable Items</p>
      <p>
        Certain items are non-refundable. These include:
      </p>
      <ul>
        <li>Online test subscriptions after they have been accessed.</li>
        <li>Downloadable materials that have been downloaded.</li>
      </ul>
      
      <p>Processing Refunds</p>
      <p>
        Once we receive your refund request, we will review it and notify you of the approval or rejection of your refund. If approved, your refund will be processed, and a credit will automatically be applied to your original method of payment within 7-8 business days.
      </p>
      
      <p>Late or Missing Refunds</p>
      <p>
        If you haven’t received a refund yet, first check your bank account again. Then contact your credit card company, it may take some time before your refund is officially posted. If you’ve done all of this and still have not received your refund, please contact us at <a href="mailto:support@nizhaltnpsc.com">support@nizhaltnpsc.com</a>.
      </p>
    </div>
  );
};

export default RefundPolicy;
