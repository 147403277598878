import React, { useState } from 'react';
import './index.css';
import { Link, useNavigate } from 'react-router-dom';
import { useUser } from '../UserContext';
import { FaUserCircle, FaSignOutAlt } from 'react-icons/fa';
import Cookies from 'js-cookie';
import { MdWorkspacePremium } from "react-icons/md";
import { FaUserAlt, FaStar, FaCrown } from 'react-icons/fa';
import { logEvent } from '../../analytics';

function Navbar() {
  const navigate = useNavigate();
  const { nizhalUser, setNizhalUser } = useUser();
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);


  const handleLogout = () => {

    logEvent('Log out', 'Clicked logout', 'logout');

    setNizhalUser(null);

    navigate('/');

    localStorage.removeItem('nizhalUser');
  };


  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const renderPlanIcon = (plan) => {
    switch (plan.toLowerCase()) {
      case 'premium':
        return <><FaCrown className="plan-icon premium" /> {capitalizeFirstLetter(plan)}</>;
      case 'basic':
        return <><FaStar className="plan-icon basic" /> {capitalizeFirstLetter(plan)}</>;
      case 'entry':
        return <><FaUserAlt className="plan-icon entry" /> {capitalizeFirstLetter(plan)}</>;
      default:
        return <><MdWorkspacePremium className="plan-icon" /> {capitalizeFirstLetter(plan)}</>;
    }
  };

  return (
    <header className="na-nav-header">
      <div onClick={() => navigate('/')} className="na-nav-logo-container">
        <img src='\assets\logoTNPSC.jpg' alt="Nizhal TNPSC Logo" className="na-nav-logo-img" />
        <p className="na-nav-logo-text">NIZHAL TNPSC</p>
      </div>
      <nav className="na-nav-nav">
        <ul className={isNavOpen ? 'open' : ''}>
          <li><Link to="/" onClick={() => setIsNavOpen(false)}>Home</Link></li>
          <li><Link to="/weekly-test" onClick={() => setIsNavOpen(false)}>Weekly Test</Link></li>
          <li><Link to="/mcq" onClick={() => setIsNavOpen(false)}>MCQ</Link></li>
          <li><Link to="/plans" onClick={() => setIsNavOpen(false)}>Plans</Link></li>
          <li><Link to="/materials" onClick={() => setIsNavOpen(false)}>Materials</Link></li>
        </ul>
      </nav>
      {nizhalUser && nizhalUser.userName ? (
        <div className="na-nav-profile-container">
          <div className="na-nav-profile-name">
            <p>{capitalizeFirstLetter(nizhalUser.userName)}</p>
            <span>({renderPlanIcon(nizhalUser.userPlan)})</span>
          </div>
          <div className="na-nav-profile-icon" onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
            <img src={nizhalUser.userImageUrl || "https://wallpapers.com/images/featured/cute-profile-picture-s52z1uggme5sj92d.jpg"} alt="Profile" className="na-nav-profile-img" />
          </div>
          {isDropdownOpen && (
            <div className="na-nav-dropdown">
              <Link to="/" onClick={() => setIsDropdownOpen(false)}><FaUserCircle /> Account</Link>
              <button className="na-nav-dropdown-logout" onClick={handleLogout}><FaSignOutAlt /> Logout</button>
            </div>
          )}
        </div>
      ) : (
        <button className="na-nav-button" onClick={() => navigate('/login')}>Login</button>
      )}
    </header>
  );
}

export default Navbar;
