import React from 'react';
import './index.css'; // Assuming you have a CSS file for styling

const HelpPage = () => {
    return (
        <div className="help-page">
            <h1>Help & Support</h1>

            <section className="weekly-test">
                <h2>Weekly Test</h2>
                <p>You can attempt the weekly test only once per week, specifically on Sundays. The questions are different for each test attempt, ensuring a unique experience every time.</p>
            </section>

            <section className="mcq-test">
                <h2>MCQ Test</h2>
                <p>The MCQ test can be attempted every day without any restrictions. Each test attempt presents different questions to ensure a varied and comprehensive testing experience.</p>
            </section>

            <section className="aptitude-test">
                <h2>Aptitude Test</h2>
                <p>After completing the aptitude test, you can download a PDF that includes the questions you attempted, the correct answers, and your answers. This helps you review your performance and prepare better.</p>
            </section>

            <section className="materials">
                <h2>Materials</h2>
                <p>You can download study materials by clicking the download button provided for each material. Make sure to check for the latest updates and additions.</p>
            </section>

            <section className="buy-premium">
                <h2>Buy Premium</h2>
                <p>To access additional features and premium content, you can buy a plan by clicking the "Buy Now" button. Choose the plan that best suits your needs and enhance your learning experience.</p>
            </section>

            <section className="contact">
                <h2>Contact Us</h2>
                <p>If you have any questions or need further assistance, please contact us:</p>
                <ul>
                    <li>Email: support@NIZHALTNPSC.com</li>
                </ul>
            </section>
        </div>
    );
}

export default HelpPage;
