import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './index.css';

const PaymentFailure = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Redirect to home page after 5 seconds
    const timer = setTimeout(() => {
      navigate('/');
    }, 5000);

    return () => clearTimeout(timer); // Cleanup on component unmount
  }, [navigate]);

  return (
    <div className="payment-failure-container">
      <div className="image-container">
        <img src="\assets\comp_3.webp" alt="Payment Failure" className="failure-image" />
      </div>
      <div className="message-container">
        <h1 className="failure-message">Payment Failed</h1>
        <p className="redirect-message">
          Something went wrong. Please try again or <button onClick={() => navigate('/')} className="retry-button">return to home page</button>.
        </p>
      </div>
    </div>
  );
};

export default PaymentFailure;
