import React, { createContext, useState, useContext } from 'react';

const TestContext = createContext();

export const useTest = () => useContext(TestContext);

export const TestProvider = ({ children }) => {
  const [stnd, setStandard] = useState(null);
  const [subject, setSubject] = useState(null);
  const [topic,setTopic] = useState(null)
  const [aptitudeResponse,setAptitudeResponse] = useState(null)
  const [weeklyTestData,setWeeklyTestData] = useState(null)
  const [medium, setMedium] = useState('TM');
  const [noOfQuestions, setNoOfQuestions] = useState(20);


  return (
    <TestContext.Provider value={{ stnd, setStandard, subject, setSubject,topic, setTopic,aptitudeResponse,setAptitudeResponse,weeklyTestData, setWeeklyTestData,medium, setMedium,noOfQuestions, setNoOfQuestions}}>
      {children}
    </TestContext.Provider>
  );
};
