import React, { createContext, useState, useEffect, useContext } from 'react';

const UserContext = createContext();

export const useUser = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
  const [nizhalUser, setNizhalUser] = useState(() => {
    const storedUser = localStorage.getItem('nizhalUser');
    return storedUser ? JSON.parse(storedUser) : {
      userName: null,
      userImageUrl: null,
      userId: null,
      userEmail: null,
      userPlan: null,
      userCount: null,
    };
  });

  useEffect(() => {
    localStorage.setItem('nizhalUser', JSON.stringify(nizhalUser));
  }, [nizhalUser]);

  return (
    <UserContext.Provider value={{ nizhalUser, setNizhalUser }}>
      {children}
    </UserContext.Provider>
  );
};
