import React, { useEffect, useRef } from 'react';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import './index.css';
import { useTest } from '../TestContext';
import { useUser } from '../UserContext';

const GenerateStyledPdf = ({ questions, selectedOptions, score, onGeneratePdf }) => {
  const printRef = useRef();
  const {nizhalUser} = useUser();

  useEffect(() => {
    if (onGeneratePdf) {
      handleGeneratePdf();
    }
  }, [onGeneratePdf]);
  console.log(nizhalUser.userName)

  const handleGeneratePdf = async () => {
    const element = printRef.current;
    const canvas = await html2canvas(element, { scale: 2 });
    const data = canvas.toDataURL('image/png');
    const pdf = new jsPDF('p', 'pt', 'a4');

    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();

    pdf.addImage(data, 'PNG', 0, 0, pdfWidth, pdfHeight);

    // Add watermark text
    pdf.setTextColor(150);
    pdf.setFontSize(60);
    pdf.text('Nizhal TNPSC', pdfWidth / 2, pdfHeight / 2, {
      align: 'center',
      angle: 45,
    });

    pdf.save('styled.pdf');
  };

  return (
    <div>
      <div id="pdf-content" ref={printRef}>
        <div className="watermark">
          <img src="/assets/logoTNPSC.jpg" alt="Watermark" />
          {/* <h1>NIZHAL TNPSC</h1> */}
        </div>
        <div id="header-content">
          <img src="/assets/logoTNPSC.jpg" alt="Logo" />
          <h1>Nizhal TNPSC</h1>
          <h1>Name : {nizhalUser.userName}</h1>
          <h2>Get Success in Your Exam</h2>
        </div>
        <div id="main-content">
          <h3>தலைப்பு: தமிழ்</h3>
          <h4>Subheading: MCQ Questions</h4>
          <table id="question-table">
            <thead>
              <tr>
                <th>Question</th>
                <th>Correct Answer</th>
                <th>Your Answer</th>
              </tr>
            </thead>
            <tbody>
              {questions.map((q, index) => (
                <tr key={index}>
                  <td>{q.question_text}</td>
                  <td>{q.answer}</td>
                  <td>{selectedOptions[index] || 'Not Answered'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default GenerateStyledPdf;