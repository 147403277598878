import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import './index.css';
import { IoMdHome } from "react-icons/io";
import { TbClockPlay, TbPremiumRights } from "react-icons/tb";
import { FaTelegram } from "react-icons/fa6";
import MenuBookIcon from '@mui/icons-material/MenuBook';
import BorderColorIcon from '@mui/icons-material/BorderColor';

const NavbarDown = () => {
  const location = useLocation();
  const currentPath = location.pathname;

  return (
    <div className="navbar">
      <Link to="/" className={currentPath === '/' ? 'active' : ''}>
        <div className={currentPath === '/' ? 'navbar-item active' : 'navbar-item'}>
          <IoMdHome />
          <p>Home</p>
        </div>
      </Link>
      <Link to="/mcq" className={currentPath === '/mcq' ? 'active' : ''}>
        <div className={currentPath === '/mcq' ? 'navbar-item active' : 'navbar-item'}>
          <BorderColorIcon />
          <p>Test</p>
        </div>
      </Link>
      <Link to="/weekly-test" className={currentPath === '/weekly-test' ? 'active' : ''}>
        <div className={currentPath === '/weekly-test' ? 'navbar-item active' : 'navbar-item'}>
          <TbClockPlay />
          <p>Weekly Test</p>
        </div>
      </Link>
      <Link to="/plans" className={currentPath === '/plans' ? 'active' : ''}>
        <div className={currentPath === '/plans' ? 'navbar-item active' : 'navbar-item'}>
          <TbPremiumRights />
          <p>Buy Plan</p>
        </div>
      </Link>
      <Link to="/materials" className={currentPath === '/materials' ? 'active' : ''}>
        <div className={currentPath === '/materials' ? 'navbar-item active' : 'navbar-item'}>
          <MenuBookIcon />
          <p>Book</p>
        </div>
      </Link>
      {/* <div className="navbar-action">
        <FaTelegram />
      </div> */}
    </div>
  );
};

export default NavbarDown;
