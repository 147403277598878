import React from 'react';
import './index.css';

const ConfirmationModal = ({ onConfirm, onCancel }) => {
  return (
    <div className="confirmation-modal-overlay">
      <div className="confirmation-modal">
        <h2>Warning</h2>
        <p>Your test will be terminated if you leave. Are you sure you want to exit?</p>
        <div className="modal-buttons">
          <button className="confirm-button" onClick={onConfirm}>Yes, Exit</button>
          <button className="cancel-button" onClick={onCancel}>Cancel</button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
