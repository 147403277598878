import React, {useState, useEffect} from 'react';
import './index.css';
import Card from '../Card';
import Footer from '../Footer';
import WhyChooseUs from '../WhyChooseUs';
import GoogleLoginModal from '../GoogleLoginModal';
import Cookies from 'js-cookie';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4'; 
import { useUser } from '../UserContext';
import { Helmet } from 'react-helmet';
import { logEvent } from '../../analytics';


// const introImageURL = 'https://example.com/images/1.png';
const aboutImageURL = 'https://example.com/images/2.png';
const mcqImageURL = 'https://example.com/images/3.png';

function Home() {
  const [showModal, setShowModal] = useState(false);
  const [user, setUser] = useState(null);
  const { nizhalUser, setNizhalUser } = useUser();
  

  const location = useLocation();

  useEffect(() => {
    ReactGA.initialize('G-QLVFZS683E'); 
    ReactGA.send('pageview');
  }, [location]);

  useEffect(() => {
    const storedUser = localStorage.getItem('nizhalUser');
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
  }, []);


  useEffect(() => {
    if (!nizhalUser || !nizhalUser.userId) {
      setShowModal(true);
    }
  }, []); 
  

  const handleLoginSuccess = (user) => {
    setNizhalUser(user);
    setShowModal(false);

    logEvent('User', 'Logged In', user.userEmail);
  };


  



  return (
    <div className="home">
        <Helmet>
        <title>TNPSC MCQ Test Preparation | Nizhal TNPSC</title>
        <meta name="description" content="Prepare for TNPSC exams with our comprehensive MCQ tests. Get access to practice questions, detailed explanations, and performance analytics to ace your exam!" />
        <meta name="keywords" content="TNPSC, MCQ tests, exam preparation, study materials, TNPSC practice questions" />
        <meta property="og:title" content="TNPSC MCQ Test Preparation" />
        <meta property="og:description" content="Prepare for TNPSC exams with our comprehensive MCQ tests. Get access to practice questions, detailed explanations, and performance analytics to ace your exam!" />
        <meta property="og:url" content="https://nizhaltnpsc.com" />
        <meta property="og:site_name" content="NIZHAL TNPSC" />
      </Helmet>
     {showModal && <GoogleLoginModal onClose={() => setShowModal(false)} onSuccess={handleLoginSuccess} />}
  <div className="intro-section">
    <div className="intro-content">
      <h1 className="intro-heading">TNPSC Exam Preparation</h1>
      <h2 className="intro-text">முயற்சியும்  பயிற்சியும் தொடர்ச்சியாக இருக்குமேயானால், சாதாரன மனிதனும்  சாதனையாளராகலாம்!!!!</h2>
      {/* <div className="stats">
        <div  className="stat-card">
          <h1>100+</h1>
           <p>Satisfied Clients</p>
           </div>
        <div className="stat-card">
          <h1>10+</h1>
          <p>Awards Earned</p>
          </div>
        <div className="stat-card">
          <h1>4.9</h1>
           <p>Google Ratings</p>
           </div>
      </div> */}

    </div>
    <div className='image-container'>
      
    <img className="intro-image" src="/assets/homepageSideBook.png" alt="Intro" />
    </div>
  </div>
<Card/>
<WhyChooseUs/>
<Footer/>
    </div>
  );
}

export default Home;
