import React, { useState, useEffect, useRef } from 'react';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { useTest } from '../TestContext';
import GenerateStyledPdf from '../TamilPdf';
import {Oval} from 'react-loader-spinner';
import './index.css';
import { useUser } from '../UserContext';

import { Navigate } from 'react-router-dom';
import { logEvent } from '../../analytics';
const WeeklyTestPage = () => {
  const { nizhalUser, setNizhalUser } = useUser();
  const { weeklyTestData } = useTest();
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);
  const [score, setScore] = useState(0);
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [completed, setCompleted] = useState(false);
  const [timeLeft, setTimeLeft] = useState(2 * 60 * 60);
  const [attemptedQuestions, setAttemptedQuestions] = useState(0);
  const [generateTamil, setGenerateTamil] = useState(false);
  const timerRef = useRef(null);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        setQuestions(weeklyTestData.selectedQuestions);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchQuestions();
  }, [weeklyTestData]);

  useEffect(() => {
    if (timeLeft > 0) {
      timerRef.current = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    } else {
      clearInterval(timerRef.current);
      setCompleted(true);
    }
    return () => clearInterval(timerRef.current);
  }, [timeLeft]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (!completed) {
        const message = 'You have an ongoing test. Are you sure you want to leave?';
        event.returnValue = message; // Standard for most browsers
        return message; // For some older browsers
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [completed]);

  const handleNextClick = () => {
    if (selectedOption && questions[currentQuestion]) {
      const selectedAnswer = selectedOption.charAt(0);
      if (selectedAnswer === questions[currentQuestion].answer.charAt(0) || selectedOption === questions[currentQuestion].answer) {
        setScore(score + 1);
      }
      setAttemptedQuestions(attemptedQuestions + 1);
      setSelectedOption(null);

      if (currentQuestion < questions.length - 1) {
        setCurrentQuestion(currentQuestion + 1);
      } else {
        setCompleted(true);
      }
    }
  };

  // const generatePDF = () => {
  //   const doc = new jsPDF();
  //   doc.text('MCQ Practice - 1', 10, 10);
  //   doc.text(`Score: ${score}`, 10, 20);

  //   const data = questions.map((q, index) => [
  //     `Q${index + 1}: ${q.question_text}`,
  //     `Correct Answer: ${q.answer}`,
  //     `Your Answer: ${selectedOption || 'Not Answered'}`,
  //   ]);

  //   doc.autoTable({
  //     head: [['Question', 'Correct Answer', 'Your Answer']],
  //     body: data,
  //   });

  //   doc.save('test_results.pdf');
  // };

  const handleGenerateTamilPDF = () => {
    logEvent('TEST PAGE', 'Clicked Download PDF', nizhalUser.userEmail);
    setGenerateTamil(true);
  };


  const formatTime = (seconds) => {
    const h = Math.floor(seconds / 3600);
    const m = Math.floor((seconds % 3600) / 60);
    const s = seconds % 60;
    return `${h.toString().padStart(2, '0')}:${m.toString().padStart(2, '0')}:${s.toString().padStart(2, '0')}`;
  };

  const handleExitAssignment = () => {
    clearInterval(timerRef.current);
    setCompleted(true);
  };

  if (loading) return <div className="test-loader-container">
  <Oval type="ThreeDots" color="#ffffff" height={80} width={80} />
  <p>Your test is loading, please wait...</p>
</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="test-container">
       
      <div className="header">
        <h1>MOCK TEST - 1</h1>
        <div className="timer">
          Time Left: {formatTime(timeLeft)}
        </div>
        <button className="exit-assignment-button" onClick={handleExitAssignment}>
          Exit Assignment
        </button>
      </div>
      {questions.length > 0 && !completed && (
        <>
          <div className="question-section">
            <h2>Question {currentQuestion + 1}</h2>
            <p>{questions[currentQuestion].question_text}</p>
          </div>
          <div className="options-section">
            {questions[currentQuestion].options.map((option, index) => (
              <label key={index} className="option-label">
                <input
                  type="radio"
                  name="option"
                  id={`option-${index}`}
                  className="option-input"
                  onClick={() => handleOptionClick(option)}
                  checked={selectedOption === option}
                />
                <div className={`option-button ${selectedOption === option ? 'selected' : ''}`}>
                  <span>{option}</span>
                </div>
              </label>
            ))}
          </div>
          <div className="action-section">
            <button className="next-button" onClick={handleNextClick} disabled={selectedOption === null}>
              Submit
            </button>
          </div>
        </>
      )}
      {completed && (
        <div className="completion-section">
          <h2>Test Completed</h2>
          <div className="score-section">
            <p>Score: <span className="score-value">{score}</span></p>
            <div className="score-ratio">
              <div className="score-bar">
                <div className="score-fill" style={{ width: `${(score / questions.length) * 100}%` }}></div>
              </div>
              <p className="score-percentage">{((score / questions.length) * 100).toFixed(2)}%</p>
            </div>
          </div>
          <ul className="test-summary">
            <li className="total-questions">
              <span className="bullet blue"></span>
              Total Questions: {questions.length}
            </li>
            <li className="attempted-questions">
              <span className="bullet yellow"></span>
              Questions Attempted: {attemptedQuestions}
            </li>
            <li className="not-attempted-questions">
              <span className="bullet red"></span>
              Wrong Answers: {questions.length - attemptedQuestions}
            </li>
          </ul>
          <button className="download-pdf-button" onClick={handleGenerateTamilPDF}>
          Download Tamil PDF
        </button>
      
      <button onClick={Navigate('/mcq')}>Go to MCQ</button>

      {generateTamil && questions.length > 0 && (
        <GenerateStyledPdf 
          questions={questions} 
          selectedOptions={selectedOption} 
          score={score}
          onGeneratePdf={generateTamil}
        />
      )}


          {timeLeft <= 0 && <p>Time is over</p>}
        </div>
      )}
    </div>
  );
};

export default WeeklyTestPage;
