import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { TiTick } from "react-icons/ti";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { tamilFontBase64 } from './noto-sans-tamil';
import { useTest } from '../TestContext';
import { useUser } from '../UserContext';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { Oval } from 'react-loader-spinner';
import ConfirmationModal from '../ConfirmationModal';
import { useNavigate } from 'react-router-dom';

import './index.css';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const TestPage = () => {
  const { nizhalUser, setNizhalUser } = useUser();
  const { stnd, subject, medium, noOfQuestions } = useTest();
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);
  const [isAnswerCorrect, setIsAnswerCorrect] = useState(null);
  const [showExplanation, setShowExplanation] = useState(false);
  const [score, setScore] = useState(0);
  const [questions, setQuestions] = useState([]);
  const [testLoading, setTestLoading] = useState(true);
  const [error, setError] = useState(null);
  const [completed, setCompleted] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [isTestStarted, setIsTestStarted] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const navigate = useNavigate();
  let [standard, setStandard] = useState(stnd);

  const handleBeforeUnload = (event) => {
    if (isTestStarted) {
      event.preventDefault();
      event.returnValue = ''; // This is required for the prompt to show in some browsers
    }
  };

  const handleNavigation = (event) => {
    if (isTestStarted) {
      event.preventDefault();
      setShowConfirmationModal(true);
    }
  };

  const handleExitTest = () => {
    setShowConfirmationModal(false);
    navigate('/mcq')
  };

  const handleCancelExit = () => {
    setShowConfirmationModal(false);
  };

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isTestStarted]);

  useEffect(() => {
    window.addEventListener('popstate', handleNavigation);
    return () => {
      window.removeEventListener('popstate', handleNavigation);
    };
  }, [isTestStarted]);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

  const addTamilFont = () => {
    const doc = new jsPDF();
    doc.addFileToVFS('TamilFont.ttf', tamilFontBase64);
    doc.addFont('TamilFont.ttf', 'TamilFont', 'normal');
    return doc;
  };

  useEffect(() => {
    const fetchQuestions = async () => {
      let standard = stnd;

      if (stnd.includes('Tamil') || stnd.includes('Tenth')) {
        standard = stnd;
      } else {
        standard = stnd + medium;
      }

      if (!nizhalUser || !nizhalUser.userId) {
        return;
      }

      try {
        console.log(noOfQuestions)
        const response = await axios.post('https://2mn4dxxw3hj2yrhqzbsxdyirva0uksoy.lambda-url.ap-south-1.on.aws/questions', {
          userId: nizhalUser.userId,
          standard,
          subject,
          noOfQuestions
        });
        const questionsWithSelectedOption = response.data.questions.map(question => ({
          ...question,
          selectedOption: null
        }));
        setQuestions(questionsWithSelectedOption);
        const userData = response.data.user;
        const storedUser = {
          userName: userData.displayName,
          userImageUrl: userData.profileImageUrl,
          userId: userData.uniqueId,
          userEmail: userData.email,
          userPlan: userData.plan, 
          userCount: userData.count,
        };
  
        setNizhalUser(storedUser); 
        setIsTestStarted(true);
        setTestLoading(false);
      } catch (err) {
        setError(err.message);
        setTestLoading(false);
        setIsTestStarted(false);
      }
    };

    fetchQuestions();
  }, [standard]);

  const handleNextClick = () => {
    const selectedAnswer = selectedOption.charAt(0);
    const newSelectedOptions = [...selectedOptions];
    newSelectedOptions[currentQuestion] = selectedOption;

    if (selectedAnswer === questions[currentQuestion].answer.charAt(0) || selectedOption === questions[currentQuestion].answer) {
      setIsAnswerCorrect(true);
      setScore(score + 1);
    } else {
      setIsAnswerCorrect(false);
    }
    setSelectedOptions(newSelectedOptions);
    setShowExplanation(false);
  };

  const handleNextQuestion = () => {
    if (currentQuestion < questions.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
      setSelectedOption(null);
      setIsAnswerCorrect(null);
      setShowExplanation(false);
    } else {
      handleCompleteTest();
    }
  };

  const handleShowExplanationClick = () => {
    setShowExplanation(true);
  };

  const generatePDF = () => {
    const docDefinition = {
      content: [
        { text: 'MCQ Practice - 1', fontSize: 15 },
        { text: `Score: ${score}`, fontSize: 12 },
        { text: 'Questions:', fontSize: 12, margin: [0, 10, 0, 10] },
        {
          table: {
            headerRows: 1,
            widths: ['*', '*', '*'],
            body: [
              ['Question', 'Correct Answer', 'Your Answer'],
              ...questions.map((q, index) => [
                `Q${index + 1}: ${q.question_text}`,
                `Correct Answer: ${q.answer}`,
                `Your Answer: ${selectedOptions[index] || 'Not Answered'}`,
              ]),
            ],
          },
        },
      ],
      defaultStyle: {
        font: 'Roboto',
      },
    };

    pdfMake.createPdf(docDefinition).download('test_results.pdf');
  };

  const attemptedQuestions = questions.filter((q, index) => q.answer === selectedOptions[index]).length;

  if (testLoading) return (
    <div className="test-loader-container">
      <Oval type="ThreeDots" color="#ffffff" height={80} width={80} />
      <p>Your test is loading, please wait...</p>
    </div>
  );
  if (error) return <div>Error: {error}</div>;

  const handleCompleteTest = () => {
    navigate('/score', { state: { score, questions, selectedOptions } });
  };

  return (
    <>
      <div className="test-container">
        <div className="header">
          <h1>MCQ Practice - 1</h1>
          <div className="score">
            Score: {score}
          </div>
        </div>
        <div className="question-section">
          <h2>Question {currentQuestion + 1}</h2>
          <p>{questions[currentQuestion].question_text}</p>
        </div>
        <div className="options-section">
          {questions[currentQuestion].options.map((option, index) => (
            <label key={index} className="option-label">
              <input
                type="radio"
                name="option"
                id={`option-${index}`}
                className="option-input"
                onClick={() => handleOptionClick(option)}
                checked={selectedOption === option}
                disabled={isAnswerCorrect !== null}
              />
              <div className={`option-button ${selectedOption === option ? (isAnswerCorrect === null ? 'selected' : (isAnswerCorrect ? 'correct' : 'incorrect')) : ''}`}>
                <span>{option}</span>
                {isAnswerCorrect !== null && (
                  <>
                    {option.charAt(0) === questions[currentQuestion].answer.charAt(0) && (
                      <span className="result correct">
                        <TiTick size={20} />
                      </span>
                    )}
                    {selectedOption === option && !isAnswerCorrect && (
                      <span className="result incorrect">
                        ❌
                      </span>
                    )}
                  </>
                )}
              </div>
            </label>
          ))}
        </div>
        <div className="action-section">
          {isAnswerCorrect === null ? (
            <button className="next-button" onClick={handleNextClick} disabled={selectedOption === null}>
              Submit
            </button>
          ) : (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              {!(stnd ==="grammerTamil")&&(<button className="show-explanation-button" onClick={handleShowExplanationClick}>
                Show Explanation
              </button>)}
              {currentQuestion < questions.length - 1 && (
                <button className="next-question-button" onClick={handleNextQuestion}>
                  Next Question
                </button>
              )}
              {currentQuestion >= questions.length - 1 && (
                <button className="complete-test-button" onClick={handleCompleteTest}>
                  Complete Test
                </button>
              )}
            </div>
          )}
        </div>
        {showExplanation && (
          <div className="explanation-section">
            <p>{questions[currentQuestion].explanation}</p>

          </div>
        )}
        {completed && (
          <div className="complete-section">
            <p>Test completed!</p>
            <p>Your score: {score} out of {questions.length}</p>
            <p>Attempted questions: {attemptedQuestions}</p>
            <button className="generate-pdf-button" onClick={generatePDF}>
              Generate PDF
            </button>
          </div>
        )}
        {showConfirmationModal && (
          <ConfirmationModal
            onConfirm={handleExitTest}
            onCancel={handleCancelExit}
            message="Are you sure you want to exit the test? Your progress will be lost."
          />
        )}
      </div>
    </>
  );
};

export default TestPage;