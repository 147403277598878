import React, { useEffect, useRef, useState } from 'react';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useUser } from '../UserContext';
import { FaTimes } from 'react-icons/fa';
import { Oval } from 'react-loader-spinner';
import './index.css';

const GoogleLoginModal = ({ onClose, onSuccess }) => {
  const { setNizhalUser } = useUser();
  const [isLoading, setIsLoading] = useState(false);
  const modalRef = useRef(null);

  const handleLoginSuccess = async (response) => {
    setIsLoading(true);
    try {
      const { credential } = response;
      const googleResponse = await axios.post(
        'https://2mn4dxxw3hj2yrhqzbsxdyirva0uksoy.lambda-url.ap-south-1.on.aws/auth/google/callback',
        { token: credential },
        { withCredentials: true }
      );



      // const user = googleResponse.data;
      // setNizhalUser(user);

      const userData = googleResponse.data;

      const storedUser = {
        userName: userData.displayName,
        userImageUrl: userData.profileImageUrl,
        userId: userData.uniqueId,
        userEmail: userData.email,
        userPlan: userData.plan, 
        userCount: userData.count,
      };

      setNizhalUser(storedUser); 
      onSuccess(storedUser);
      
      setIsLoading(false);


      onClose();
    } catch (error) {
      console.error('Login failed', error);
      setIsLoading(false);
    }
  };

  const handleLoginFailure = (response) => {
    console.error('Login failed', response);
  };

  const handleOutsideClick = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  return (
    <div className="modal-overlay">
      <div className="modal-content" ref={modalRef}>
        <button className="close-btn" onClick={onClose}>
          <FaTimes />
        </button>
        <img src="/assets/logoTNPSC.jpg" alt="Nizhal TNPSC Logo" className="login-modal-logo-img" />
        <h2>Login with Google</h2>
        <GoogleOAuthProvider clientId="402163496970-qhqqclg0u2s6g2hro85fie1cnli4n8cv.apps.googleusercontent.com">
          {isLoading ? (
            <div className="loader-container">
              <Oval height={40} width={40} color="#4fa94d" />
            </div>
          ) : (
            <GoogleLogin
              onSuccess={handleLoginSuccess}
              onFailure={handleLoginFailure}
              cookiePolicy={'single_host_origin'}
              render={(renderProps) => (
                <>
                  <button
                    className="google-login-btn"
                    onClick={renderProps.onClick}
                    disabled={renderProps.disabled}
                  >
                    <img
                      src="https://developers.google.com/identity/images/g-logo.png"
                      alt="Google Logo"
                      style={{ marginRight: '10px' }}
                    />
                    Sign in with Google
                  </button>
                </>
              )}
            />
          )}
        </GoogleOAuthProvider>
      </div>
    </div>
  );
};

export default GoogleLoginModal;
