import React, {useState} from 'react';
import { useParams,useNavigate } from 'react-router-dom';
import { useUser } from '../UserContext';
import LoginNotificationModal from '../LoginNotificationModal';

import './index.css';


const bookData = {
  6: {
    One: [
      { subject: 'Tamil',linkTM:"https://dropbox.com/scl/fi/nywdlqoollxi5idha5dxa/6th_Tamil_Term_I-www.tntextbooks.in.pdf?rlkey=g0lgdkmdhnyvxc5wq0pvy9eq5&st=x9o5ddp1&dl=1", link: 'https://dropbox.com/scl/fi/nywdlqoollxi5idha5dxa/6th_Tamil_Term_I-www.tntextbooks.in.pdf?rlkey=g0lgdkmdhnyvxc5wq0pvy9eq5&st=x9o5ddp1&dl=1'},
      { subject: 'English', linkTM:"https://www.dropbox.com/scl/fi/uuf96knmkp3ejjy4aau9l/6th_English_Term_I-www.tntextbooks.in.pdf?rlkey=a7jpt4q03j2k9dph0dif3db44&st=eqlzrs1l&dl=1" , link: 'https://www.dropbox.com/scl/fi/uuf96knmkp3ejjy4aau9l/6th_English_Term_I-www.tntextbooks.in.pdf?rlkey=a7jpt4q03j2k9dph0dif3db44&st=eqlzrs1l&dl=1' },
      { subject: 'Maths',  linkTM:"https://www.dropbox.com/scl/fi/6q05oobrpae3p90qtdc8j/6th_Mathematics_Term_I_TM-www.tntextbooks.in.pdf?rlkey=po2xzy99plvbkjgn425ddgigt&st=6o2kxogv&dl=1", link: 'https://www.dropbox.com/scl/fi/qtibjf9prrpff9dmauksi/6th_Mathematics_Term_I_EM-www.tntextbooks.in.pdf?rlkey=5otef1qnymthmp5fon1j3i9if&st=fhkwn7dc&dl=1' },
      { subject: 'Science', linkTM:"https://www.dropbox.com/scl/fi/lvvirmmx7lsumhv4hqtai/6th_Science_Term_I_TM-www.tntextbooks.in.pdf?rlkey=mpxbk4jlwmi8ap225xg5kntju&st=hwvtpc4o&dl=1", link: 'https://www.dropbox.com/scl/fi/3bti7mrebggsa5tillfov/6th_Science_Term_I_EM-www.tntextbooks.in.pdf?rlkey=27183i6xf2dxb04bxmo6nf7yn&st=5tucx3j4&dl=1' },
      { subject: 'Social',  linkTM:"https://www.dropbox.com/scl/fi/dgbzzt1knnge46kw3s16s/6th_Social_Science_Term_I_TM-www.tntextbooks.in.pdf?rlkey=6zazz6hgkkkzz35t8vp93sj9h&st=ly5pjo6w&dl=1", link: 'https://www.dropbox.com/scl/fi/anrbyklrz5aqpp5pj56vv/6th_Social_Science_Term_I_EM-www.tntextbooks.in.pdf?rlkey=67ce2wubhyjsafiyxicdsb2v2&st=0qxoh6rn&dl=1' },
    ],
    Two: [
      { subject: 'Tamil',  linkTM:"https://www.dropbox.com/scl/fi/tyzpsa5olrnmmc8yodi0w/6th_Tamil_Term_II_TM-www.tntextbooks.in.pdf?rlkey=nvwh2upk0odhepldylowprqr5&st=tbaftvvc&dl=1", link: 'https://www.dropbox.com/scl/fi/tyzpsa5olrnmmc8yodi0w/6th_Tamil_Term_II_TM-www.tntextbooks.in.pdf?rlkey=nvwh2upk0odhepldylowprqr5&st=tbaftvvc&dl=1' },
      { subject: 'English',  linkTM:"https://www.dropbox.com/scl/fi/k3x9su2t6qoqviiillp4g/6th_English_Term_II_EM-www.tntextbooks.in.pdf?rlkey=gfhm5w16jptvuzhu0kj1ae6i6&st=jvppjl99&dl=1", link: 'https://www.dropbox.com/scl/fi/k3x9su2t6qoqviiillp4g/6th_English_Term_II_EM-www.tntextbooks.in.pdf?rlkey=gfhm5w16jptvuzhu0kj1ae6i6&st=jvppjl99&dl=1' },
      { subject: 'Maths',  linkTM:"https://www.dropbox.com/scl/fi/5bn2cfurmd7d9x82c5tct/6th_Mathematics_Term_II_TM-www.tntextbooks.in.pdf?rlkey=esci08hfmye1p59sdw0ojy5u6&st=6595txot&dl=1" , link: 'https://www.dropbox.com/scl/fi/z61p9ckzb4lzxea9faxi8/6th_Mathematics_Term_II_EM-www.tntextbooks.in.pdf?rlkey=egz909pdiku5ww4mth3sqnimj&st=vxi3pnfz&dl=1' },
      { subject: 'Science', linkTM:"https://www.dropbox.com/scl/fi/6qyxx5kwpjwjh5u853v7s/6th_Science_Term_II_TM-www.tntextbooks.in.pdf?rlkey=h24wr5x1lnj15itykox758vee&st=7zc4m0n2&dl=1" , link: 'https://www.dropbox.com/scl/fi/phyjo10ih8pb7np9pqrmb/6th_Science_Term_II_EM-www.tntextbooks.in.pdf?rlkey=plvg6d7yxld7t3qoi1hwko01n&st=w1y8j1is&dl=1' },
      { subject: 'Social',  linkTM:"https://www.dropbox.com/scl/fi/gwl2b3lve8lvzb70tgq4y/6th_Social_Science_Term_II_TM-www.tntextbooks.in.pdf?rlkey=mlxptz6em54twi2epvlhuu9ga&st=zyh6ljcg&dl=1" , link: 'https://www.dropbox.com/scl/fi/luo4tgwgkgqt1ls8597r4/6th_Social_Science_Term_II_EM-www.tntextbooks.in.pdf?rlkey=knkl96fw11idjuvv0lz330coz&st=4akp8wxd&dl=1' },
    ],
    Three: [
      { subject: 'Tamil',  linkTM:"https://www.dropbox.com/scl/fi/pgfwn9ttyk1v20krvcr59/6th-Term-III-Tamil-www.tntextbooks.in.pdf?rlkey=h3xlt5ui6nvcadxlgo1c0ol1u&st=92eok4q3&dl=1", link: 'https://www.dropbox.com/scl/fi/pgfwn9ttyk1v20krvcr59/6th-Term-III-Tamil-www.tntextbooks.in.pdf?rlkey=h3xlt5ui6nvcadxlgo1c0ol1u&st=92eok4q3&dl=1' },
      { subject: 'English',  linkTM:"https://www.dropbox.com/scl/fi/30xtau26ftg6so90tvw78/6th-Term-III-English-www.tntextbooks.in.pdf?rlkey=6pt8kmbd3u5vrfsduomg5toms&st=usx90wb2&dl=1", link: 'https://www.dropbox.com/scl/fi/30xtau26ftg6so90tvw78/6th-Term-III-English-www.tntextbooks.in.pdf?rlkey=6pt8kmbd3u5vrfsduomg5toms&st=usx90wb2&dl=1' },
      { subject: 'Maths',  linkTM:"https://www.dropbox.com/scl/fi/ubba3fw4n6reh4qodzs66/6th-Term-III-Maths-TM-www.tntextbooks.in.pdf?rlkey=ercpm0bmr0nj0z6igy1ddgsrl&st=c9c9n24j&dl=1", link: 'https://www.dropbox.com/scl/fi/grgaxpl89iop28yj4kwme/6th_Mathematics_Term_III_EM-www.tntextbooks.in.pdf?rlkey=h82bthdui58urp7wb9y3xumte&st=o2j4veov&dl=1' },
      { subject: 'Science',  linkTM:"https://www.dropbox.com/scl/fi/cwvi1df9mcrh9t7yutrfb/6th_Science_Term_III_TM-www.tntextbooks.in.pdf?rlkey=pywc8ldlc9j465axe8dtrsx9i&st=cde50nd5&dl=1", link: 'https://www.dropbox.com/scl/fi/9lr1bt9aivoamscgkgp3z/6th_Science_Term_III_EM-www.tntextbooks.in.pdf?rlkey=ouc402nw028kf2bykqqsfew3f&st=j90ps4gd&dl=1' },
      { subject: 'Social', linkTM:"https://www.dropbox.com/scl/fi/501tvh9cdpo2fvoxpbhgu/6th_Social_Science_Term_III_TM-www.tntextbooks.in.pdf?rlkey=576lht3ut9mol21upyouwxjvd&st=6xb3i0xe&dl=1", link: 'https://www.dropbox.com/scl/fi/8phfe1aml5fwf83ufv94b/6th_Social_Science_Term_III_EM-www.tntextbooks.in.pdf?rlkey=q5uuox0cga04o0jfwffjwcgtr&st=wktdrl0e&dl=1' },
    ],
  },
  7: {
    One: [
      { subject: 'Tamil',  linkTM:"https://www.dropbox.com/scl/fi/0wn20bpy4odjl8taxyhzh/7th_Tamil_Term_I-www.tntextbooks.in.pdf?rlkey=lhpdfr0xiy8fkynm825bbay8k&st=5oqhntkw&dl=1", link: 'https://www.dropbox.com/scl/fi/0wn20bpy4odjl8taxyhzh/7th_Tamil_Term_I-www.tntextbooks.in.pdf?rlkey=lhpdfr0xiy8fkynm825bbay8k&st=5oqhntkw&dl=1' },
      { subject: 'English',  linkTM:"https://www.dropbox.com/scl/fi/etnqxikj11prrmwo6zkwp/7th_English_Term_I-www.tntextbooks.in.pdf?rlkey=2oislrr86qvpm1sn21h1giubl&st=90kfvxzw&dl=1", link: 'https://www.dropbox.com/scl/fi/etnqxikj11prrmwo6zkwp/7th_English_Term_I-www.tntextbooks.in.pdf?rlkey=2oislrr86qvpm1sn21h1giubl&st=90kfvxzw&dl=1' },
      { subject: 'Maths',  linkTM:"https://www.dropbox.com/scl/fi/eal4yn53yb6unfpr9k58a/7th_Mathematics_Term_I_TM_www.tntextbooks.in.pdf?rlkey=lvsrx8waqtt3f0jz822vm5sdn&st=bcq7lybx&dl=1", link: 'https://www.dropbox.com/scl/fi/0yl8uo0mppze1r5k8jixx/7th_Mathematics_Term_I_EM_www.tntextbooks.in.pdf?rlkey=dmkxh6v0h6lu9zm62rgj30abb&st=2hcqh24m&dl=1' },
      { subject: 'Science',  linkTM:"https://www.dropbox.com/scl/fi/s29isin0415esejweu92b/7th_Science_Term_I_TM-www.tntextbooks.in.pdf?rlkey=f7ll14t6plhxk4kholyryia8l&st=f5zrc639&dl=1", link: 'https://www.dropbox.com/scl/fi/2o4z829bvj70o84o316k5/7th_Science_Term_I_EM_www.tntextbooks.in.pdf?rlkey=l9khtmptryt24eakjhljt8zqj&st=xz2ys172&dl=1' },
      { subject: 'Social', linkTM:"https://www.dropbox.com/scl/fi/trcmykzlmcaz8yqrfx0ne/7th_Social_Science_Term_I_TM-www.tntextbooks.in.pdf?rlkey=we1y7uqbj02anew13wmvqatdy&st=dzva12kx&dl=1", link: 'https://www.dropbox.com/scl/fi/xducy8brdpoqm4ejrv552/7th_Social_Science_Term_I_EM_www.tntextbooks.in.pdf?rlkey=6vwny9a2ghjyie30q9vf0ysvu&st=93zpc35m&dl=1' },
    ],
    Two: [
      { subject: 'Tamil', linkTM:"https://www.dropbox.com/scl/fi/4kxc4sal9nky2nlmjsjzc/7th_Tamil_Term_II_TM_www.tntextbooks.in.pdf?rlkey=30p8ubbzpl5f25geahj8qjndd&st=sgc4x8y2&dl=1", link: 'https://www.dropbox.com/scl/fi/4kxc4sal9nky2nlmjsjzc/7th_Tamil_Term_II_TM_www.tntextbooks.in.pdf?rlkey=30p8ubbzpl5f25geahj8qjndd&st=sgc4x8y2&dl=1' },
      { subject: 'English', linkTM:"https://www.dropbox.com/scl/fi/wmle4e90re6n439vjifzn/7th_English_Term_II_EM_www.tntextbooks.in.pdf?rlkey=zftq3mwcybw03wwb1ye1pueq9&st=bh0i7o23&dl=1", link: 'https://www.dropbox.com/scl/fi/wmle4e90re6n439vjifzn/7th_English_Term_II_EM_www.tntextbooks.in.pdf?rlkey=zftq3mwcybw03wwb1ye1pueq9&st=bh0i7o23&dl=1' },
      { subject: 'Maths',  linkTM:"https://www.dropbox.com/scl/fi/udl0ekwgkkdl2fh2mfcv1/7th_Std_Term_II_Maths_TM_www.tntextbooks.in.pdf?rlkey=rv404yet89h4hvgrc4n329gv4&st=mvjx4vew&dl=1", link: 'https://www.dropbox.com/scl/fi/5crwnb8rdq0jluvzvkce7/7th_Mathematics_Term_II_EM_www.tntextbooks.in.pdf?rlkey=cef703hjkmsnvzewwiqwx2hy5&st=i3514ly9&dl=1' },
      { subject: 'Science',  linkTM:"https://www.dropbox.com/scl/fi/pz9kl0ahrpqjwviehopwu/7th_Science_Term_II_TM_www.tntextbooks.in.pdf?rlkey=m6q7lul6r5d14gsag9nq5xzbu&st=rlmo4snl&dl=1", link: 'https://www.dropbox.com/scl/fi/wwfgwiqbn9oro6qhz6bq6/7th_Science_Term_II_EM_www.tntextbooks.in.pdf?rlkey=q26ghw6vbn6b9303gm2g0yqda&st=0j52982s&dl=1' },
      { subject: 'Social',  linkTM:"https://www.dropbox.com/scl/fi/z73jz7zt3hj2cx8e7rt8b/7th_Social_Science_Term_II_TM_www.tntextbooks.in.pdf?rlkey=dz1kn7pi07oa4809j35zds49z&st=kcxd2cna&dl=1", link: 'https://www.dropbox.com/scl/fi/75gp8zbuhu4ojr52jdlax/7th_Social_Science_Term_II_EM_www.tntextbooks.in.pdf?rlkey=33byy3kbuom5a6elcwp9yxyqx&st=hlsixlm4&dl=1' },
    ],
    Three: [
      { subject: 'Tamil', linkTM:"https://www.dropbox.com/scl/fi/p7bbmvshf9bifgn3ma0g4/7th_Tamil_Term_III_TM_www.tntextbooks.in.pdf?rlkey=ckg4ox3ae05rb3v6isagmkhlz&st=muxjskee&dl=1", link: 'https://www.dropbox.com/scl/fi/p7bbmvshf9bifgn3ma0g4/7th_Tamil_Term_III_TM_www.tntextbooks.in.pdf?rlkey=ckg4ox3ae05rb3v6isagmkhlz&st=muxjskee&dl=1' },
      { subject: 'English', linkTM:"https://www.dropbox.com/scl/fi/xbpfpen6c04jrp9jz2qet/7th_English_Term_III_EM_www.tntextbooks.in.pdf?rlkey=iw3epmrybwc8zv16sly1hc4aw&st=d4pvywzm&dl=1", link: 'https://www.dropbox.com/scl/fi/xbpfpen6c04jrp9jz2qet/7th_English_Term_III_EM_www.tntextbooks.in.pdf?rlkey=iw3epmrybwc8zv16sly1hc4aw&st=d4pvywzm&dl=1' },
      { subject: 'Maths', linkTM:"https://www.dropbox.com/scl/fi/uep27ygdcf6jk811eu02r/7th_-Mathematics_Term_III_TM_www.tntextbooks.in.pdf?rlkey=dxexpo9cmnocmntqaqpimbk0i&st=0cxttywm&dl=1", link: 'https://www.dropbox.com/scl/fi/xtmstjigf7pkpae6ahalx/7th_Mathematics_Term_III_EM_www.tntextbooks.in.pdf?rlkey=uwxs48aoaivq19amlahv5n8xf&st=y55p46i3&dl=1' },
      { subject: 'Science', linkTM:"https://www.dropbox.com/scl/fi/wycpg09epmnlzz53em32a/7th_Science_Term_III_TM_www.tntextbooks.in.pdf?rlkey=pcv3xzels54wl2ycx4qkjru29&st=neclj1j5&dl=1", link: 'https://www.dropbox.com/scl/fi/gh06fbh65t5pqvmiqik8z/7th_Science_Term_III_EM_www.tntextbooks.in.pdf?rlkey=9tsjwcjwj5fdqoxy4itxt6xgz&st=yzv1emov&dl=1' },
      { subject: 'Social', linkTM:"https://www.dropbox.com/scl/fi/1ay84p44wt6l1ad98hvoe/7th_Social_Science_Term_III_TM_www.tntextbooks.in.pdf?rlkey=la40usus92g4alapiwdmu6gwk&st=v5321uo7&dl=1", link: 'https://www.dropbox.com/scl/fi/6yiftp8kvyquya0hiidif/7th_Social_Science_Term_III_EM_www.tntextbooks.in.pdf?rlkey=faom96db0e6tyekw4gos7vtq0&st=edcxor3a&dl=1' },
    ],
  },
  8: {
    One: [
      { subject: 'Tamil', linkTM:"https://www.dropbox.com/scl/fi/9foxu8n7elaobdesf4b4m/8th_Std_Term_I_Tamil.pdf?rlkey=2da456g1gqp5d82gi4hz6ig1y&st=g6p0qjv4&dl=1", link: 'https://www.dropbox.com/scl/fi/9foxu8n7elaobdesf4b4m/8th_Std_Term_I_Tamil.pdf?rlkey=2da456g1gqp5d82gi4hz6ig1y&st=g6p0qjv4&dl=1' },
      { subject: 'English', linkTM:"https://www.dropbox.com/scl/fi/obi0jw7fshoslhn0005y0/8th_Std_Term_I_English.pdf?rlkey=342ldvvapauxefoutzab65mr7&st=dm62pdhy&dl=1", link: 'https://www.dropbox.com/scl/fi/obi0jw7fshoslhn0005y0/8th_Std_Term_I_English.pdf?rlkey=342ldvvapauxefoutzab65mr7&st=dm62pdhy&dl=1' },
      { subject: 'Maths', linkTM:"https://www.dropbox.com/scl/fi/yliu144td45yt6ojd99yc/8th_Std_Term_I_Mathematics_TM.pdf?rlkey=dgfrwqfytyximeqk8mjb5pr7e&st=8o3c32tq&dl=1", link: 'https://www.dropbox.com/scl/fi/vw8u324n8szufeyynyx66/8th_Std_Term_I_Mathematics_EM.pdf?rlkey=rbgchel6148jsasoys79e826y&st=crx0g2k5&dl=1' },
      { subject: 'Science', linkTM:"", link: 'https://www.dropbox.com/scl/fi/34538myy7s8n7teyryo2m/8th_Std_Term_I_Science_EM.pdf?rlkey=94ivi62yfybzycocb7pipjhdw&st=bu7h95ei&dl=1' },
      { subject: 'Social', linkTM:"", link: 'https://www.dropbox.com/scl/fi/3sxd275joo8q3ilfc5zvt/8th_Std_Term_I_Social_Science_EM.pdf?rlkey=lfge0nzfwiyx6n1otmt8k4ux4&st=mwa04i4s&dl=1' },
    ],
    Two: [
      { subject: 'Tamil', linkTM:"https://www.dropbox.com/scl/fi/knrpb22c2y45s7usmwzx9/8th_Tamil_Term_II_www.tntextbooks.in.pdf?rlkey=w5q3ddxj6txixab74go4m9yb4&st=x6oe99yh&dl=1", link: 'https://www.dropbox.com/scl/fi/knrpb22c2y45s7usmwzx9/8th_Tamil_Term_II_www.tntextbooks.in.pdf?rlkey=w5q3ddxj6txixab74go4m9yb4&st=x6oe99yh&dl=1' },
      { subject: 'English', linkTM:"https://www.dropbox.com/scl/fi/fo81q1jcdp5amdrheq9fs/8th_English_Term_II_www.tntextbooks.in.pdf?rlkey=jp0fb1iv5og94axjatnsuj3mu&st=r4pxuj5g&dl=1", link: 'https://www.dropbox.com/scl/fi/fo81q1jcdp5amdrheq9fs/8th_English_Term_II_www.tntextbooks.in.pdf?rlkey=jp0fb1iv5og94axjatnsuj3mu&st=r4pxuj5g&dl=1' },
      { subject: 'Maths', linkTM:"", link: 'https://www.dropbox.com/scl/fi/ijlvgp8vf5bz21mk4dobr/8th_Std_Term_II_Maths_EM.pdf?rlkey=ys0okovq1l3r4ca2myxdnkj4n&st=2z2if1b9&dl=1' },
      { subject: 'Science', linkTM:"https://www.dropbox.com/scl/fi/vcnbq15vaqko5kvs145g9/8th_Science_Term_II_TM_www.tntextbooks.in.pdf?rlkey=ew3hha921k4jiz3ke06d25gho&st=c9lv2hfc&dl=1", link: 'https://www.dropbox.com/scl/fi/3agwe5esbgyf303it9dm1/8th_Science_Term_II_EM_www.tntextbooks.in.pdf?rlkey=usfdn9oxablvk7ikvegypbhms&st=ymk2oh6i&dl=1' },
      { subject: 'Social', linkTM:"https://www.dropbox.com/scl/fi/zej60yffoj5xwo045fve6/8th_Social_Science_Term_II_TM_www.tntextbooks.in.pdf?rlkey=18ciwl0zejc0v75aru4200xsw&st=5xfaon0s&dl=1", link: 'https://www.dropbox.com/scl/fi/10oarpqmv94c78udbwsck/8th_Social_Science_Term_II_EM_www.tntextbooks.in.pdf?rlkey=7rzhkm81tbn8bckhn2cjfs181&st=an45q9ms&dl=1' },
    ],
    Three: [
      { subject: 'Tamil', linkTM:"https://www.dropbox.com/scl/fi/mvuedm0cib494cyldvs95/8th_Tamil_Term-III_www.tntextbooks.in.pdf?rlkey=t58ptw8cxh91if2o9hzusb6xt&st=jr4u1tsi&dl=1", link: 'https://www.dropbox.com/scl/fi/mvuedm0cib494cyldvs95/8th_Tamil_Term-III_www.tntextbooks.in.pdf?rlkey=t58ptw8cxh91if2o9hzusb6xt&st=jr4u1tsi&dl=1' },
      { subject: 'English', linkTM:"https://www.dropbox.com/scl/fi/b4y0ex256ksehy49dlkoz/8th_English_Term-III_www.tntextbooks.in.pdf?rlkey=mbnf3bix2nhls6ht1f0jrpunm&st=hcot2fw9&dl=1", link: 'https://www.dropbox.com/scl/fi/b4y0ex256ksehy49dlkoz/8th_English_Term-III_www.tntextbooks.in.pdf?rlkey=mbnf3bix2nhls6ht1f0jrpunm&st=hcot2fw9&dl=1' },
      { subject: 'Maths', linkTM:"https://www.dropbox.com/scl/fi/y6fyimwqjrmwm1b3dd3ar/8th_Maths_Term-III_TM_www.tntextbooks.in.pdf?rlkey=xvi4n2qtpff9ie6b43t03s1of&st=92e26pss&dl=1", link: 'https://www.dropbox.com/scl/fi/qvt3fga818zuqsv9r8mur/8th_Maths_Term-III_EM_www.tntextbooks.in.pdf?rlkey=jlg9bw0c1302j8s7mt8fcry2z&st=rkk6at89&dl=1' },
      { subject: 'Science', linkTM:"https://www.dropbox.com/scl/fi/40okfyqstfygb0e9vos44/8th_Science_Term_III_TM_www.tntextbooks.in.pdf?rlkey=kgagoibjq3ixixbmkzs8wuuwj&st=6cu7gmc2&dl=1", link: 'https://www.dropbox.com/scl/fi/yrvorq33grza8zcni6v5l/8th_Science_Term_III_EM_www.tntextbooks.in.pdf?rlkey=ykndlcqcfwpj1je3nx7cvimku&st=5mnc3r98&dl=1' },
      { subject: 'Social', linkTM:"https://www.dropbox.com/scl/fi/tjah9jnavz76pqy8vou2w/8th_Social_Science_Term_III_TM_www.tntextbooks.in.pdf?rlkey=olchaw63m6cpfvharsgnl624u&st=qkmec8v8&dl=1", link: 'https://www.dropbox.com/scl/fi/5yx8q1x4667washvbnmwi/8th_Social_Science_Term_III_EM_www.tntextbooks.in.pdf?rlkey=yyayfanryuzui33z552xb1hiy&st=nqm4rga8&dl=1' },
    ],
  },
  9: {
    One: [
      { subject: 'Tamil', linkTM:"https://www.dropbox.com/scl/fi/i3v6h2xvgs4n6ezv2684h/9th_Tamil.pdf?rlkey=zoaaecps6aywbty0o8d1d3uph&st=ai0l561w&dl=1", link: 'https://www.dropbox.com/scl/fi/i3v6h2xvgs4n6ezv2684h/9th_Tamil.pdf?rlkey=zoaaecps6aywbty0o8d1d3uph&st=ai0l561w&dl=1' },
      { subject: 'English', linkTM:"https://www.dropbox.com/scl/fi/etnqxikj11prrmwo6zkwp/7th_English_Term_I-www.tntextbooks.in.pdf?rlkey=2oislrr86qvpm1sn21h1giubl&st=90kfvxzw&dl=1", link: 'https://www.dropbox.com/scl/fi/etnqxikj11prrmwo6zkwp/7th_English_Term_I-www.tntextbooks.in.pdf?rlkey=2oislrr86qvpm1sn21h1giubl&st=90kfvxzw&dl=1' },
      { subject: 'Maths', linkTM:"https://www.dropbox.com/scl/fi/l99jpuxhdwfsz97n003ga/9th_Maths_TM_Term1.pdf?rlkey=y58zkqwgqxm69tqaw0wxyhlnv&st=uz5w1r1s&dl=1", link: 'https://www.dropbox.com/scl/fi/0yl8uo0mppze1r5k8jixx/7th_Mathematics_Term_I_EM_www.tntextbooks.in.pdf?rlkey=dmkxh6v0h6lu9zm62rgj30abb&st=2hcqh24m&dl=1' },
      { subject: 'Science', linkTM:"https://www.dropbox.com/scl/fi/szidial06121prlutu6ay/9th_Science_TM-1.pdf?rlkey=yp3456aou4stei6muq5187jxd&st=0hiitax9&dl=1", link: 'https://www.dropbox.com/scl/fi/jj2b1hnmldcl5g0hseadd/9th_Science_EM.pdf?rlkey=4xyil4zyfrqow77u8hhyzrzsk&st=e8ra56a2&dl=1' },
      { subject: 'Social', linkTM:"https://www.dropbox.com/scl/fi/wbtsy754wyk0d4pzumd6z/9th-Social-Science_TM_Term_1.pdf?rlkey=77iu80m4rsvdqpi6a1w2gxu20&st=6s13h9lc&dl=1", link: 'https://www.dropbox.com/scl/fi/xducy8brdpoqm4ejrv552/7th_Social_Science_Term_I_EM_www.tntextbooks.in.pdf?rlkey=6vwny9a2ghjyie30q9vf0ysvu&st=93zpc35m&dl=1' },
    ],
    Two: [
      { subject: 'Tamil', linkTM:"https://www.dropbox.com/scl/fi/pzog5qmghtyl3ybzggsxa/9th_Tamil_Term_II_TM.pdf?rlkey=omjxdgvsyww1im6irjuy0p85r&st=cdspmb8v&dl=1", link: 'https://www.dropbox.com/scl/fi/pzog5qmghtyl3ybzggsxa/9th_Tamil_Term_II_TM.pdf?rlkey=omjxdgvsyww1im6irjuy0p85r&st=cdspmb8v&dl=1' },
      { subject: 'English', linkTM:"https://www.dropbox.com/scl/fi/wmle4e90re6n439vjifzn/7th_English_Term_II_EM_www.tntextbooks.in.pdf?rlkey=zftq3mwcybw03wwb1ye1pueq9&st=bh0i7o23&dl=1", link: 'https://www.dropbox.com/scl/fi/wmle4e90re6n439vjifzn/7th_English_Term_II_EM_www.tntextbooks.in.pdf?rlkey=zftq3mwcybw03wwb1ye1pueq9&st=bh0i7o23&dl=1' },
      { subject: 'Maths', linkTM:"https://www.dropbox.com/scl/fi/fdfo9f2b8bvrk8vygaal8/9th_Maths_Term_II_TM.pdf?rlkey=ws2p0vfkqsseklrog43fk1m2n&st=o8d9mnvz&dl=1", link: 'https://www.dropbox.com/scl/fi/xf31bfry9dot5qj5ix98t/9th_Maths_Term_II_EM.pdf?rlkey=fxdex4flu5k7a26xgqn87qa6i&st=6io2u1tw&dl=1' },
      { subject: 'Science', linkTM:"https://www.dropbox.com/scl/fi/k82y7lnp208rol6p0bcbt/9th_Science_Term_II_TM-1.pdf?rlkey=es1kiafumgh1zdvswjgnlaqlq&st=8nvxtb07&dl=1", link: 'https://www.dropbox.com/scl/fi/hobugqg86dwn8tikn5n7y/9th_Science_Term_II_EM.pdf?rlkey=7l5clab8xm90td9lzwxgzphgr&st=68fdcvw6&dl=1' },
      { subject: 'Social', linkTM:"https://www.dropbox.com/scl/fi/ilcifc3nxqeczojpjltz1/9th_Social_Science_Term_II_TM.pdf?rlkey=g3gfc2w5af5i4fq5labxf9qp9&st=uye4pmob&dl=1", link: 'https://www.dropbox.com/scl/fi/yp3rgvdnctkqg8cpklsxw/9th_Social_Science_Term_II_EM.pdf?rlkey=kcavh38r9qc3pv91b3v8yrzsf&st=9q76g1uq&dl=1' },
    ],
    Three: [
      { subject: 'Tamil', linkTM:"https://www.dropbox.com/scl/fi/mhj5vohq6n28bgga6h3p7/IX-Std-Tamil-Term-III-www.tntextbooks.in.pdf?rlkey=1iwxqutettdsvmksr27hcduit&st=x7cm76wv&dl=1", link: 'https://www.dropbox.com/scl/fi/mhj5vohq6n28bgga6h3p7/IX-Std-Tamil-Term-III-www.tntextbooks.in.pdf?rlkey=1iwxqutettdsvmksr27hcduit&st=x7cm76wv&dl=1' },
      { subject: 'English', linkTM:"https://www.dropbox.com/scl/fi/avvcu1ia6bylfks874muj/IX-Std-English-Term-III-www.tntextbooks.in.pdf?rlkey=6dsvf6fiqelwoggtbva1uhpb2&st=t3oi8iwn&dl=1", link: 'https://www.dropbox.com/scl/fi/avvcu1ia6bylfks874muj/IX-Std-English-Term-III-www.tntextbooks.in.pdf?rlkey=6dsvf6fiqelwoggtbva1uhpb2&st=t3oi8iwn&dl=1' },
      { subject: 'Maths', linkTM:"https://www.dropbox.com/scl/fi/8oxtawsa5vr07cx6b1mbt/9th_Maths_Term_III_TM-www.tntextbooks.in.pdf?rlkey=dctk06fhtzwnqerddf2ojiudr&st=srhn2ud2&dl=1", link: 'https://www.dropbox.com/scl/fi/qlac2uk4zbvx393ulji8a/9th_Maths_Term_III_EM-www.tntextbooks.in.pdf?rlkey=hxu5ifpuf05dpcvwnajk5omrx&st=rymnto2x&dl=1' },
      { subject: 'Science', linkTM:"https://www.dropbox.com/scl/fi/m1lgwi8830ch9c6gfx2ok/9th_Science_Term_III_TM-www.tntextbooks.in.pdf?rlkey=ihh7h9s601kfned2mjd2upogc&st=gz4qre5i&dl=1", link: 'https://www.dropbox.com/scl/fi/dqpm4j0y0ukna9kb7eqi0/9th_Science_Term_III_EM-www.tntextbooks.in.pdf?rlkey=872uou4eezsw2wyrxsv6lmt4v&st=z0ikjf8h&dl=1' },
      { subject: 'Social', linkTM:"https://www.dropbox.com/scl/fi/8oxtawsa5vr07cx6b1mbt/9th_Maths_Term_III_TM-www.tntextbooks.in.pdf?rlkey=dctk06fhtzwnqerddf2ojiudr&st=srhn2ud2&dl=1", link: 'https://www.dropbox.com/scl/fi/qs3my3msn2uftjijnefsh/9th_Social_Science_Term_III_EM-www.tntextbooks.in.pdf?rlkey=rhm8xrullcbkf1i3soye974k6&st=g1i66t4s&dl=1' },
    ],
  },
  // Similar structure for grades 7, 8, and 9
  10: [
    { subject: 'Tamil', linkTM:"https://www.dropbox.com/scl/fi/oxpui8lnaveg3vvraic78/10th_Tamil-www.tntextbooks.in-1.pdf?rlkey=273o4opttvt82li7u2xk3joib&st=0nihpy7b&dl=1", link: 'https://www.dropbox.com/scl/fi/oxpui8lnaveg3vvraic78/10th_Tamil-www.tntextbooks.in-1.pdf?rlkey=273o4opttvt82li7u2xk3joib&st=0nihpy7b&dl=1' },
    { subject: 'English', linkTM:"https://www.dropbox.com/scl/fi/ioqlfbt0vyzx3kc0mwej4/10th_English_EM_www.tntextbooks.in.pdf?rlkey=kcwu7ycc4jlvdgwbuqswe3yml&st=5webygj2&dl=1", link: 'https://www.dropbox.com/scl/fi/ioqlfbt0vyzx3kc0mwej4/10th_English_EM_www.tntextbooks.in.pdf?rlkey=kcwu7ycc4jlvdgwbuqswe3yml&st=5webygj2&dl=1' },
    { subject: 'Maths', linkTM:"https://www.dropbox.com/scl/fi/oropujb8ut4orhnv24hsg/10th_Mathematics_TM_www.tntextbooks.in.pdf?rlkey=fiatdxykwygypm6yg4l4rpyee&st=zrg27mce&dl=1", link: 'https://www.dropbox.com/scl/fi/24gc595k82kzvskwlsbny/10th_Mathematics_EM_www.tntextbooks.in.pdf?rlkey=lpxkwp2xxjirpumr4cdsivxmw&st=dfczg63m&dl=1' },
    { subject: 'Science', linkTM:"https://www.dropbox.com/scl/fi/1wnmndl5wx03y3c8yume8/10th_Science_TM_www.tntextbooks.in.pdf?rlkey=mkq7l3puuem179v278mnl0n0m&st=o9cu8lp2&dl=1", link: 'https://www.dropbox.com/scl/fi/wn6go6xil9l6trk7eu3s6/10th_Science_EM_www.tntextbooks.in.pdf?rlkey=swb7kbgoo8lc5ngkddf9f5nos&st=q41udnio&dl=1' },
    { subject: 'Social', linkTM:"https://www.dropbox.com/scl/fi/ayz1lk6ojoi8k0sg3olhu/10th_Social_Science_TM_www.tntextbooks.in.pdf?rlkey=vqkczanlf58b23mmlifp9gaau&st=ubel8f5v&dl=1", link: 'https://www.dropbox.com/scl/fi/xlchy9vtciyel63xtrwdx/10th_Social_Science_EM_www.tntextbooks.in.pdf?rlkey=q65frwoncb3ur7zmppcp8ep6w&st=pgtdyryg&dl=1' },
  ],
  11: {
    LanguageSubjects:[
      { subject: 'தமிழ்(Tamil)', linkTM: '', link: 'https://www.dropbox.com/scl/fi/j82yb426mw6nskc0exyrb/11th_General_Tamil-www.tntextbooks.in.pdf?rlkey=ghyd0cvbvzrowcued22r05q9w&st=faey4nnt&dl=1' },
      { subject: 'ஆங்கிலம் (English)', linkTM: '', link: 'https://www.dropbox.com/scl/fi/drngd4hwl7a8ztf5o5avd/11th_General_English_EM_www.tntextbooks.in.pdf?rlkey=pbdxmdltll8orb0xbtpqyy7rm&st=eh90yyaz&dl=1' },
      // { subject: 'சிறப்புத் தமிழ்', linkTM: '', link: 'link-to-tamil-grade-6' },
      // { subject: 'Communicative English', linkTM: '', link: 'link-to-english-grade-6' },
      ],
    GeneralSubjects : [
      { subject: 'கணக்குப் பதிவியல்', linkTM: 'https://www.dropbox.com/scl/fi/oh9ulubc3u9wq1an6v7hh/11th_Accountancy_TM_www.tntextbooks.in.pdf?rlkey=llv8qs1vfcfrv2bsnou2yamwn&st=xlvr1m8o&dl=1', link: 'https://www.dropbox.com/scl/fi/1z94z8l4kna5jw8twdusj/11th_Accountancy_EM_www.tntextbooks.in.pdf?rlkey=c0i3rsxs35bvhomna65g4ar75&st=zhx7rmi7&dl=1' },
      // { subject: 'உயிர் தாவரவியல்', link: 'link-to-english-grade-7' },
      // { subject: 'நுண்ணுயிரியல்', link: 'link-to-tamil-grade-6' },
      // { subject: 'தாவரவியல்', link: 'link-to-english-grade-6' },
      // { subject: 'வணிகக் கணிதம் மற்றும் புள்ளியியல்', link: 'link-to-english-grade-6' },
      { subject: 'இயற்பியல் I', linkTM: 'https://www.dropbox.com/scl/fi/by5xoiubjw7idps2laq99/11th_Physical_Vol-1_TM_www.tntextbooks.in.pdf?rlkey=4t7jmmor38bvfjygdcps3aphf&st=cysdh1il&dl=1', link: 'https://www.dropbox.com/scl/fi/w9v73stynosp8aqims2o0/11th_Physics_Vol-1_EM_www.tntextbooks.in.pdf?rlkey=qy9tv8akgipynla2ilh4ywbaa&st=aaww1jnv&dl=1' },
      { subject: 'இயற்பியல் II', linkTM: 'https://www.dropbox.com/scl/fi/qq4dtw1cnk7kmbji3e6w6/11th_Physics_Vol-2_TM_www.tntextbooks.in.pdf?rlkey=duz02ar6oz755fgoz1k52h5gi&st=uznjr274&dl=1', link: 'https://www.dropbox.com/scl/fi/v7itngnv1mrrqpnpnxsqp/11th_Physics_Vol_2_EM-www.tntextbooks.in.pdf?rlkey=o6rh4a28by221r4kclrro7gr2&st=szldbusr&dl=1' },
      { subject: 'வேதியியல் I', linkTM: 'https://www.dropbox.com/scl/fi/4v1ywn90jjnhp7c1oj53h/11th_Chemistry_Vol-1_TM_www.tntextbooks.in.pdf?rlkey=ibe2qdwagn8r38go4hye9a6u8&st=t8x5aabk&dl=1', link: 'https://www.dropbox.com/scl/fi/kcz9j7rudbhsz4vdru2kn/11th_Chemistry_Vol-1_EM_www.tntextbooks.in.pdf?rlkey=f3d1smtx6y1wbtife3iycv3vd&st=y7w913wv&dl=1' },
      { subject: 'வேதியியல் II', linkTM: 'https://www.dropbox.com/scl/fi/mwvhvnxpv1kx1axw0ta8a/11th_Chemistry_Vol-2_TM_www.tntextbooks.in.pdf?rlkey=ids0uo4qaydigeb0g74usongu&st=6omvq0qy&dl=1', link: 'https://www.dropbox.com/scl/fi/thxiw6igxd0a9j9ywj3ky/11th_Chemistry_Vol-2_EM_www.tntextbooks.in.pdf?rlkey=sqcy2920vvv08e1u2euduhw9n&st=nc9gla1s&dl=1' },
      // { subject: 'வணிகவியல்', link: 'link-to-english-grade-6' },
      // { subject: 'கணினி பயன்பாடுகள்', link: 'link-to-english-grade-6' },
      // { subject: 'கணினி அறிவியல்', link: 'link-to-english-grade-6' },
      // { subject: 'கணினி தொழில்நுட்பம்', link: 'link-to-english-grade-6' },
      // { subject: 'பொருளியல்', link: 'link-to-english-grade-6' },
      // { subject: 'புள்ளியியல்', link: 'link-to-english-grade-6' },
      // { subject: 'மனையியல்', link: 'link-to-english-grade-6' },
      // { subject: 'வரலாறு', link: 'link-to-english-grade-6' },
      { subject: 'கணிதவியல் I', linkTM: 'https://www.dropbox.com/scl/fi/pm6u1hzdae0c4jzh1q3is/11th_Maths_Vol-1_TM_www.tntextbooks.in.pdf?rlkey=vl9xzq1dr4hmon45kcz3lb3lo&st=tryrnczl&dl=1', link: 'https://www.dropbox.com/scl/fi/4kzc81x14s43p1ahss8xq/11th_Maths_Vol-1_EM_www.tntextbooks.in.pdf?rlkey=p8jmcws99wa9fr9f58kvix7ep&st=w1jmg3v9&dl=1' },
      { subject: 'கணிதவியல் II', linkTM: 'https://www.dropbox.com/scl/fi/6ncpv1sptpi1tw9dmapvb/11th_Maths_Vol-2_TM_www.tntextbooks.in.pdf?rlkey=mtyyqgmu36unx1zi83j7x4t4m&st=19fo44en&dl=1', link: 'https://www.dropbox.com/scl/fi/0sckib81883jct19569bz/11th_Maths_Vol-2_EM_www.tntextbooks.in.pdf?rlkey=boajckkqsba3qksnjcrxl2a3l&st=c2ov6wzs&dl=1' },
      // { subject: 'அரசியல் அறிவியல்', link: 'link-to-english-grade-6' },
      // { subject: 'அறவியலும் இந்தியப் பண்பாடும்', link: 'link-to-english-grade-6' },
      // { subject: 'சத்துணவியல்', link: 'link-to-english-grade-6' },
      // { subject: 'விலங்கியல்', link: 'link-to-english-grade-6' },
      // { subject: 'புவியியல்', link: 'link-to-english-grade-6' },
      // { subject: 'பொது செவிலியம்', link: 'link-to-english-grade-6' },
    ],
    // VocationalSubjects : [
    //   { subject: 'வேலைவாய்ப்புத் திறன்கள்', link: 'link-to-english-grade-7' },
    //   { subject: 'வேளாண் அறிவியல்', link: 'link-to-english-grade-6' },
    //   { subject: 'தணிக்கையியல் செய்முறை', link: 'link-to-english-grade-6' },
    //   { subject: 'அடிப்படைத் தானியங்கி ஊர்திப் பொறியியல்', link: 'link-to-english-grade-6' },
    //   { subject: 'அடிப்படை கட்டடப் பொறியியல்', link: 'link-to-english-grade-6' },
    //   { subject: 'அடிப்படை மின் பொறியியல்', link: 'link-to-english-grade-6' },
    //   { subject: 'அடிப்படை மின்னணு பொறியியல்', link: 'link-to-english-grade-6' },
    //   { subject: 'அடிப்படை இயந்திரவியல்', link: 'link-to-english-grade-6' },
    //   { subject: 'உணவக மேலாண்மை', link: 'link-to-english-grade-6' },
    //   { subject: 'அலுவலக மேலாண்மையும் செயலியலும் மற்றும் தட்டச்சும் கணினி பயன்பாடுகளும்', link: 'link-to-english-grade-6' },
    //   { subject: 'செவிலியம்', link: 'link-to-english-grade-6' },
    //   { subject: 'நெசவியல் தொழில்நுட்பம்', link: 'link-to-english-grade-6' },
    //   { subject: 'நெசவியலும் ஆடை வடிவமைப்பும்', link: 'link-to-english-grade-6' },
    // ]
  },
  12: {
    LanguageSubjects:[
      { subject: 'தமிழ்(Tamil)', linkTM: 'https://www.dropbox.com/scl/fi/zqsp8pmi09mxadgrit0jo/12th_Tamil_TM_www.tntextbooks.in.pdf?rlkey=vrz4gpfbi5osanpwyig4xrxpd&st=a661ci47&dl=1', link: 'https://www.dropbox.com/scl/fi/zqsp8pmi09mxadgrit0jo/12th_Tamil_TM_www.tntextbooks.in.pdf?rlkey=vrz4gpfbi5osanpwyig4xrxpd&st=a661ci47&dl=1' },
      { subject: 'ஆங்கிலம் (English)', linkTM: 'https://www.dropbox.com/scl/fi/odlp7g3syadv1yn1x4d9d/12th_General_English_EM_www.tntextbooks.in.pdf?rlkey=hqj0gruegld63yeanqv8o0urp&st=61fqsody&dl=1', link: 'https://www.dropbox.com/scl/fi/odlp7g3syadv1yn1x4d9d/12th_General_English_EM_www.tntextbooks.in.pdf?rlkey=hqj0gruegld63yeanqv8o0urp&st=61fqsody&dl=1' },
      // { subject: 'சிறப்புத் தமிழ்', linkTM: '', link: 'link-to-tamil-grade-6' },
      // { subject: 'Communicative English', linkTM: '', link: 'link-to-english-grade-6' },
      ],
    GeneralSubjects : [
      { subject: 'கணக்குப் பதிவியல்', linkTM: 'https://www.dropbox.com/scl/fi/hzlzhw7ujya53t2wp8n5j/12th_Accountancy_TM_www.tntextbooks.in.pdf?rlkey=76quj10b8o1nwxf4uvao4u0d1&st=wk7r70lu&dl=1', link: 'https://www.dropbox.com/scl/fi/rvlhnwnpsntng4cmdiruy/12th_Accountancy_EM_www.tntextbooks.in.pdf?rlkey=7qwqasivl8lwrlgao01icdlvi&st=d7nvmlks&dl=1' },
      // { subject: 'உயிர் தாவரவியல்', link: 'link-to-english-grade-7' },
      // { subject: 'நுண்ணுயிரியல்', link: 'link-to-tamil-grade-6' },
      // { subject: 'தாவரவியல்', link: 'link-to-english-grade-6' },
      // { subject: 'வணிகக் கணிதம் மற்றும் புள்ளியியல்', link: 'link-to-english-grade-6' },
      { subject: 'இயற்பியல் I', linkTM: 'https://www.dropbox.com/scl/fi/p9f2ck1p6p3ziuy9cdw00/12th_Physics_Vol-1_TM_www.tntextbooks.in.pdf?rlkey=qdni12m8a5fd7wwodpvzfnvtv&st=2bnre2hu&dl=1', link: 'https://www.dropbox.com/scl/fi/bqw23n8faw0te95kmcgny/12th_Physics_Vol-1_EM_www.tntextbooks.in.pdf?rlkey=wo0a6zrdkt0kb3x8mq3yaqzf0&st=bahvb2ky&dl=1' },
      { subject: 'இயற்பியல் II', linkTM: 'https://www.dropbox.com/scl/fi/47fm70qxmvuk6d8dxmir0/12th_Physics_Vol-2_TM_www.tntextbooks.in.pdf?rlkey=jeu6rqlcmdnmiye67k02ua2gi&st=0tb83e0f&dl=1', link: 'https://www.dropbox.com/scl/fi/xkqvv93o88wjxxm14xnuk/12th_Physics_Vol-2_EM_www.tntextbooks.in.pdf?rlkey=kqbt83ermave50qv1dwunt7nt&st=z52htc3c&dl=1' },
      { subject: 'வேதியியல் I', linkTM: 'https://www.dropbox.com/scl/fi/e3qi2dt0rg3qa5ndg65nd/12th_Chemistry_Vol-1_TM_www.tntextbooks.in.pdf?rlkey=j1uwlkgg2uzax3z1dqkphxgpq&st=cdcf65x3&dl=1', link: 'https://www.dropbox.com/scl/fi/0i5utbk2egiym3p5qfiij/12th_Chemistry_Vol-1_EM_www.tntextbooks.in.pdf?rlkey=avissjcq21vib20tnm83hp9c5&st=4u26zr27&dl=1' },
      { subject: 'வேதியியல் II', linkTM: 'https://www.dropbox.com/scl/fi/qtgxlgc9mzo3x9lv6eqo7/12th_Chemistry-Vol-2_TM-www.tntextbooks.in.pdf?rlkey=lkmjgaqdex9x8cmky4sqd54bz&st=y4xczb76&dl=1', link: 'https://www.dropbox.com/scl/fi/ujmy46kwh3p6b95bc1g4f/12th_Chemistry_Vol-2_EM_www.tntextbooks.in.pdf?rlkey=6gi862ryd3kp09b7pyt854wl3&st=ejp9991u&dl=1' },
      // { subject: 'வணிகவியல்', link: 'link-to-english-grade-6' },
      // { subject: 'கணினி பயன்பாடுகள்', link: 'link-to-english-grade-6' },
      // { subject: 'கணினி அறிவியல்', link: 'link-to-english-grade-6' },
      // { subject: 'கணினி தொழில்நுட்பம்', link: 'link-to-english-grade-6' },
      // { subject: 'பொருளியல்', link: 'link-to-english-grade-6' },
      // { subject: 'புள்ளியியல்', link: 'link-to-english-grade-6' },
      // { subject: 'மனையியல்', link: 'link-to-english-grade-6' },
      // { subject: 'வரலாறு', link: 'link-to-english-grade-6' },
      { subject: 'கணிதவியல் I', linkTM: 'https://www.dropbox.com/scl/fi/x32t2oc9cdvsb0t7gtpg7/12th_Maths_Vol-1_TM_www.tntextbooks.in.pdf?rlkey=f9m1w15rwxugmjxslt3pse6uv&st=pytt4yvd&dl=1', link: 'https://www.dropbox.com/scl/fi/03qdiqkt4ck8scmf4horu/12th_Maths_Vol-1_EM_www.tntextbooks.in.pdf?rlkey=9vogqj64f445uk1asja63a0qr&st=wn08lxnb&dl=1' },
      { subject: 'கணிதவியல் II', linkTM: 'https://www.dropbox.com/scl/fi/48sndpwyg366ksv8ubkrz/12th_Maths_Vol-2_TM_www.tntextbooks.in.pdf?rlkey=h1dw8dfya7ke38cl4q144dm9q&st=utxjebb3&dl=1', link: 'https://www.dropbox.com/scl/fi/w3tmopqh6k5rnvh8omreq/12th_Maths_Vol-2_EM_www.tntextbooks.in.pdf?rlkey=1f3tw011f570h1q00v5igpoiw&st=mcdcnd5e&dl=1' },
      // { subject: 'அரசியல் அறிவியல்', link: 'link-to-english-grade-6' },
      // { subject: 'அறவியலும் இந்தியப் பண்பாடும்', link: 'link-to-english-grade-6' },
      // { subject: 'சத்துணவியல்', link: 'link-to-english-grade-6' },
      // { subject: 'விலங்கியல்', link: 'link-to-english-grade-6' },
      // { subject: 'புவியியல்', link: 'link-to-english-grade-6' },
      // { subject: 'பொது செவிலியம்', link: 'link-to-english-grade-6' },
    ],
    // VocationalSubjects : [
    //   { subject: 'வேலைவாய்ப்புத் திறன்கள்', link: 'link-to-english-grade-7' },
    //   { subject: 'வேளாண் அறிவியல்', link: 'link-to-english-grade-6' },
    //   { subject: 'தணிக்கையியல் செய்முறை', link: 'link-to-english-grade-6' },
    //   { subject: 'அடிப்படைத் தானியங்கி ஊர்திப் பொறியியல்', link: 'link-to-english-grade-6' },
    //   { subject: 'அடிப்படை கட்டடப் பொறியியல்', link: 'link-to-english-grade-6' },
    //   { subject: 'அடிப்படை மின் பொறியியல்', link: 'link-to-english-grade-6' },
    //   { subject: 'அடிப்படை மின்னணு பொறியியல்', link: 'link-to-english-grade-6' },
    //   { subject: 'அடிப்படை இயந்திரவியல்', link: 'link-to-english-grade-6' },
    //   { subject: 'உணவக மேலாண்மை', link: 'link-to-english-grade-6' },
    //   { subject: 'அலுவலக மேலாண்மையும் செயலியலும் மற்றும் தட்டச்சும் கணினி பயன்பாடுகளும்', link: 'link-to-english-grade-6' },
    //   { subject: 'செவிலியம்', link: 'link-to-english-grade-6' },
    //   { subject: 'நெசவியல் தொழில்நுட்பம்', link: 'link-to-english-grade-6' },
    //   { subject: 'நெசவியலும் ஆடை வடிவமைப்பும்', link: 'link-to-english-grade-6' },
    // ]
  }
};

function BooksPage() {
  const { grade } = useParams();
  const { nizhalUser } = useUser();
  const [showLoginModal, setShowLoginModal] = useState(false);
  const navigate = useNavigate();
  const books = bookData[grade];



  if (!books) {
    return <div>No data available for grade {grade}</div>;
  }

  const handleCloseLoginModal = () => {
    setShowLoginModal(false);
  };

  const handleLinkClick = (event, link) => {
    if (!nizhalUser || !nizhalUser.userId) {
      event.preventDefault();  
      setShowLoginModal(true); 
    } else {
      window.location.href = link;
    }
  };
  

  const renderBooksTable = (termBooks, termName) => (
    <div key={termName}>
      <h2>{termName}</h2>
      <table className="books-table">
        <thead>
          <tr>
            <th>Subject</th>
            <th>Tamil</th>
            <th>English</th>
          </tr>
        </thead>
        <tbody>
          {termBooks.map((book, index) => (
            <tr key={index}>
              <td>{book.subject}</td>
              <td><p style={{cursor: 'pointer', textDecoration: 'underline',color: 'blue'}} onClick={(e) => handleLinkClick(e, book.link)} target="_blank" rel="noopener noreferrer">Download</p></td>
              <td><p style={{cursor: 'pointer', textDecoration: 'underline',color: 'blue'}} onClick={(e) => handleLinkClick(e, book.linkTM)} target="_blank" rel="noopener noreferrer">Download</p></td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  return (
    <>
    <div className="books-container">
      <h1 className="books-title">{grade}th Text Books</h1>
      {Array.isArray(books) ? (
        renderBooksTable(books, "")
      ) : (
        <>
          {books.One && renderBooksTable(books.One, "Term I")}
          {books.Two && renderBooksTable(books.Two, "Term II")}
          {books.Three && renderBooksTable(books.Three, "Term III")}
          {books.LanguageSubjects && renderBooksTable(books.LanguageSubjects, "Language Subjects")}
          {books.GeneralSubjects && renderBooksTable(books.GeneralSubjects, "General Subjects")}
      </>
      )}
    </div>
    {showLoginModal && (
      <LoginNotificationModal onClose={()=>setShowLoginModal(false)} />
    )}
    </>
  );
}

export default BooksPage;
