import React, { useState , useEffect} from 'react';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import axios from 'axios';
import { useUser } from '../UserContext';
import LoginNotificationModal from '../LoginNotificationModal';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import {Audio} from 'react-loader-spinner'; 
import { logEvent } from '../../analytics';
import './index.css';

const PricingPlan = () => {
  const { nizhalUser, setNizhalUser } = useUser();
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [planLoading, setPlanLoading] = useState(false); 
  const navigate = useNavigate();


  // useEffect(() => {
  //   // Fetch the updated user data when the component mounts
  //   const fetchUpdatedUserData = async () => {
  //     if (nizhalUser) {
  //       try {
  //         const response = await axios.get(`https://2mn4dxxw3hj2yrhqzbsxdyirva0uksoy.lambda-url.ap-south-1.on.aws/api/user/${nizhalUser.userId}`);
  //         setNizhalUser(response.data); // Update the user context with new data
  //       } catch (error) {
  //         console.error('Failed to fetch updated user data', error);
  //       }
  //     }
  //   };

  //   fetchUpdatedUserData();
  // }, [nizhalUser, setNizhalUser]);


  const handleClick = async (price) => {
   
    if (!nizhalUser || !nizhalUser.userId) {
      setShowLoginModal(true);
      return;
    }


    logEvent('Payment', 'Payment Clicked', `user ${nizhalUser.userEmail} clicked Pricing Plan ${price}`);

    const data = {
      name: nizhalUser.userName,
      amount: price,
      userId: nizhalUser.userId,
      number: '9999999999',
      MID: 'MID' + Date.now(),
      transactionId: 'T' + Date.now(),
    };

    setPlanLoading(true); // Start loading

    try {
      const response = await axios.post('https://2mn4dxxw3hj2yrhqzbsxdyirva0uksoy.lambda-url.ap-south-1.on.aws/api/pay', data);
      if (response.data.success) {
        window.location.href = response.data.data.instrumentResponse.redirectInfo.url;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setPlanLoading(false); 
    }
  };

  const plans = [
    {
      title: 'Free Plan',
      price: 0,
      duration: '',
      features: [
        { text: '3 Free Tests MCQ', available: true },
        { text: 'No Mock Test', available: false },
        { text: 'No Study Materials', available: false },
        { text: 'No Personalized Dashboard', available: false },
      ],
      isHighlighted: false,
    },
    {
      title: 'Basic Plan',
      price: 99,
      duration: 'for 2 Months',
      features: [
        { text: 'Unlimited Test MCQ', available: true },
        { text: 'Mock Test', available: true },
        { text: 'Study Materials', available: true },
        { text: 'Personalized Dashboard', available: true },
      ],
      isHighlighted: true,
    },
    {
      title: 'Premium Plan',
      price: 199,
      duration: 'for 5 Months',
      features: [
        { text: 'Unlimited Test MCQ', available: true },
        { text: 'Mock Test', available: true },
        { text: 'Study Materials', available: true },
        { text: 'Personalized Dashboard', available: true },
      ],
      isHighlighted: false,
    },
  ];

  return (
    <>
      <Helmet>
        <title>Study Plans - NizhalTnpsc</title>
        <meta
          name="description"
          content="Explore our study plans on NizhalTnpsc to organize your TNPSC exam preparation. Find structured plans to maximize your study efficiency."
        />
        <meta
          name="keywords"
          content="study plans, TNPSC preparation, NizhalTnpsc, TNPSC exam study schedule, exam preparation plans"
        />
        <meta
          property="og:title"
          content="Study Plans - NizhalTnpsc"
        />
        <meta
          property="og:url"
          content="https://nizhaltnpsc.com/plans"
        />
        <meta
          property="og:description"
          content="Discover effective study plans on NizhalTnpsc to help you systematically prepare for your TNPSC exams. Maximize your study efforts with our curated plans."
        />
            <meta property="og:site_name" content="NIZHAL TNPSC" />
      </Helmet>

      <div className="App">
        <h1>Our Pricing Plans</h1>
        <p>Select the plan that best suits your needs for TNPSC MCQ preparation.</p>
        <div className="pricing-container">
          {plans.map((plan, index) => (
            <div key={index} className={`pricing-plan ${plan.isHighlighted ? 'highlighted' : ''}`}>
              <h2>{plan.title}</h2>
              <p className="subtitle">For TNPSC MCQ preparation</p>
              <p className="price">₹{plan.price}</p>
              {plan.duration && <p className="duration">{plan.duration}</p>}
              <ul>
                {plan.features.map((feature, index) => (
                  <li key={index}>
                    {feature.available ? <FaCheckCircle color="green" /> : <FaTimesCircle color="red" />}
                    {feature.text}
                  </li>
                ))}
              </ul>
              {plan.price !== 0 && (
                <button onClick={() => handleClick(plan.price)} className="buy-now">
                  Buy Now
                </button>
              )}
            </div>
          ))}
        </div>

        {planLoading && (
          <div className="plan-loader-container">
            <Audio type="ThreeDots" color="#00BFFF" height={80} width={80} />
            <p>Your payment is processing, please wait...</p>
          </div>
        )}
      </div>
      {showLoginModal && (
        <LoginNotificationModal onClose={() => setShowLoginModal(false)} />
      )}
    </>
  );
};

export default PricingPlan;
