import React, { useState, useEffect } from 'react';  
import { useNavigate } from 'react-router-dom';
import { useTest } from '../TestContext'; 
import { useUser } from '../UserContext';
import LoginNotificationModal from '../LoginNotificationModal';
import PlanNotificationModal from '../PlanNotificationModal';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga4';
import axios from 'axios'; 
import {Oval} from 'react-loader-spinner';
import { logEvent } from '../../analytics';
import './index.css';

function WeeklyTest() {
  const { setWeeklyTestData, setTopic } = useTest();
  const { nizhalUser } = useUser();
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  const [isTestTime, setIsTestTime] = useState(checkIfTestTime());
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showPlanModal, setShowPlanModal] = useState(false);
  const [testLoading, setTestLoading] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState('TM');
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
      setIsTestTime(checkIfTestTime());
    }, 1000);

    const handleBeforeUnload = (event) => {
      alert("test will be terminated")
      event.preventDefault();
      event.returnValue = ''; // Chrome requires returnValue to be set
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      clearInterval(timer);
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  function calculateTimeLeft() {
    const now = new Date();
    const nextSunday = new Date(now);
    nextSunday.setDate(now.getDate() + ((7 - now.getDay()) % 7 || 7));
    nextSunday.setHours(0, 0, 0, 0); // Set to midnight (start of Sunday)

    const difference = nextSunday - now;
    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((difference / 1000 / 60) % 60);
    const seconds = Math.floor((difference / 1000) % 60);

    return { days, hours, minutes, seconds };
  }

  function checkIfTestTime() {
    const now = new Date();
    return now.getDay() === 0 && now.getHours() === 0 && now.getMinutes() === 0;
  }

  const handleCloseLoginModal = () => {
    setShowLoginModal(false);
  };


const handleStartNowClick = async () => {
  setTestLoading(true);
  if (!nizhalUser || !nizhalUser.userId) {
    setShowLoginModal(true);
    return;
  }



  logEvent('WEEKLY TEST PAGE', 'Clicked START NOW BUTTON WITHOUT PREMIUM', nizhalUser.userEmail );

  // if (nizhalUser.userPlan !== "basic" && nizhalUser.userPlan !== "premium") {
  //   setShowPlanModal(true);
  //   return;
  // }

  ReactGA.event({
    category: 'Weekly test',
    action: 'start now Clicked',
    premium: true,
    user: nizhalUser.userEmail,
  });

  logEvent('WEEKLY TEST PAGE', 'Clicked START NOW BUTTON WITH PREMIUM', nizhalUser.userEmail );

  try {
    const response = await axios.post('https://2mn4dxxw3hj2yrhqzbsxdyirva0uksoy.lambda-url.ap-south-1.on.aws/api/weekly-test-em', {
      userId: nizhalUser,
      selectedLanguage: selectedLanguage,
    });


    if (response.status !== 200) {
      throw new Error('Network response was not ok');
    }
    
    const data = response.data;
    setWeeklyTestData(data); 
    setTopic('weeklyTest'); 
    navigate('/weeklyTest/testpage');
    setTestLoading(false);
  } catch (error) {
    setTestLoading(false);
    console.error('There was a problem with the fetch operation:', error);
  }
};


  return (
    <>
      <Helmet>
        <title>Weekly Mock Test - NizhalTnpsc</title>
        <meta
          name="description"
          content="Take our weekly mock test every Sunday on NizhalTnpsc to evaluate your TNPSC exam preparation and improve your performance."
        />
        <meta
          name="keywords"
          content="weekly mock test, TNPSC exam preparation, NizhalTnpsc, TNPSC practice test, Sunday mock test"
        />
        <meta property="og:title" content="Weekly Mock Test - NizhalTnpsc" />
        <meta property="og:url" content="https://nizhaltnpsc.com/weekly-test" />
        <meta
          property="og:description"
          content="Join our weekly mock test every Sunday on NizhalTnpsc to assess your TNPSC exam readiness and enhance your study strategy."
        />
            <meta property="og:site_name" content="NIZHAL TNPSC" />

      </Helmet>
      {testLoading && <div className="test-loader-container">
      <Oval type="ThreeDots" color="#ffffff" height={80} width={80} />
      <p>Your test is loading, please wait...</p>
      </div>
        }
      <div className="weekly-test-container">
        <div className="weekly-test-content">
          <h1 className="weekly-test-title">Weekly Test</h1>
          <p className="test-timing">
            Time left until next test: {timeLeft.days} days {timeLeft.hours.toString().padStart(2, '0')} hours {timeLeft.minutes.toString().padStart(2, '0')} minutes 
          </p>
          <p className="test-description">
            You can start the weekly test only once a week.
          </p>
          <p className="test-support">
            Email us at <a href="mailto:support@nizhaltnpsc.com" className="support-link">support@nizhaltnpsc.com</a> if you need additional chances.
          </p>
          <div className="language-selection">
            <label htmlFor="language-select">Select Language : </label>
            <select 
              id="language-select" 
              value={selectedLanguage} 
              onChange={(e) => setSelectedLanguage(e.target.value)}
            >
              <option value="EM">English</option>
              <option value="TM">Tamil</option>
            </select>
          </div>
          <button className="notify-button">Get Notified</button>
{/* 
          {isTestTime ? (
            <button className="notify-button">Get Notified</button>
          ) : (
            <button className="start-now-button" onClick={handleStartNowClick}>Start Now</button>
          )} */}
        </div>
      </div>
      
      {showLoginModal && (
        <LoginNotificationModal onClose={handleCloseLoginModal} />
      )}
      {showPlanModal && (
        <PlanNotificationModal onClose={() => setShowPlanModal(false)} />
      )}
    </>
  );
}

export default WeeklyTest;
