import React from 'react';
import './index.css';

const TermsAndConditions = () => {
  return (
    <div className="terms-container">
      <h1 className="terms-title">Terms and Conditions</h1>
      <p>Introduction</p>
      <p>
        Welcome to Nizhal TNPSC. By accessing our website, you agree to comply with and be bound by the following terms and conditions of use. If you disagree with any part of these terms and conditions, please do not use our website.
      </p>
      
      <p>Modification of Terms</p>
      <p>
        We reserve the right to modify these terms and conditions at any time. You should check this page periodically to ensure you are aware of any changes. Your continued use of the website after any modifications indicates your acceptance of the new terms.
      </p>
      
      <p>Use of Website</p>
      <p>
        You agree to use our website only for lawful purposes. You must not use our website in any way that breaches any applicable local, national, or international law or regulation.
      </p>
      
      <p>Intellectual Property</p>
      <p>
        All content, trademarks, and data on this website, including but not limited to software, databases, text, graphics, icons, hyperlinks, private information, designs, and agreements, are the property of or licensed to Nizhal TNPSC and as such are protected from infringement by local and international legislation and treaties.
      </p>
      
      <p>User Accounts</p>
      <p>
        To access certain features of our website, you may need to register an account. You agree to provide accurate and complete information during registration and to keep your account information updated. You are responsible for maintaining the confidentiality of your account and password and for restricting access to your computer. You agree to accept responsibility for all activities that occur under your account or password.
      </p>
      
      <p>Services</p>
      <p>
        Nizhal TNPSC provides online tests, mock tests, and study materials for TNPSC government exams. We strive to ensure the accuracy and reliability of the information provided, but we do not warrant that the content on our website is complete or error-free.
      </p>
      
      <p>Limitation of Liability</p>
      <p>
        Nizhal TNPSC will not be liable for any indirect, incidental, special, consequential, or punitive damages arising out of your use of or inability to use our website, including but not limited to any errors or omissions in any content, or any loss or damage incurred as a result of the use of any content posted, transmitted, or otherwise made available via the website.
      </p>

      <p>Website Handle</p>
      <p>This website is handled by Kiruthika R</p>
      <p>Governing Law</p>
      <p>
        These terms and conditions are governed by and construed in accordance with the laws of [India/Tamilnadu], and you irrevocably submit to the exclusive jurisdiction of the courts in that State or location.
      </p>
    </div>
  );
};

export default TermsAndConditions;
