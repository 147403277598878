import React, { useState, useEffect } from 'react';
import { TiTick } from "react-icons/ti";
import { useNavigate } from 'react-router-dom';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { useTest } from '../TestContext';
import { useUser } from '../UserContext';
import { Oval } from 'react-loader-spinner';

import './index.css';

const AptitudeTestPage = () => {
  const { aptitudeResponse } = useTest();
  const { nizhalUser, setNizhalUser } = useUser();
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);
  const [isAnswerCorrect, setIsAnswerCorrect] = useState(null);
  const [showExplanation, setShowExplanation] = useState(false);
  const [score, setScore] = useState(0);
  const [questions, setQuestions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [completed, setCompleted] = useState(false);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        console.log(aptitudeResponse)
        setQuestions(aptitudeResponse);
        setSelectedOptions(Array(aptitudeResponse.length).fill(null));
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchQuestions();
  }, [aptitudeResponse]);

  const handleNextClick = () => {
    if (selectedOption && questions[currentQuestion]) {
      const selectedAnswer = selectedOption.charAt(0);
      const newSelectedOptions = [...selectedOptions];
      newSelectedOptions[currentQuestion] = selectedOption;
      setSelectedOptions(newSelectedOptions);

      if (selectedAnswer === questions[currentQuestion].answer.charAt(0) || selectedOption === questions[currentQuestion].answer) {
        setIsAnswerCorrect(true);
        setScore(score + 1);
      } else {
        setIsAnswerCorrect(false);
      }
      setShowExplanation(false);
    }
  };

  const handleShowExplanationClick = () => {
    setShowExplanation(true);
  };

  const handleNextQuestion = () => {
    if (currentQuestion < questions.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
      setSelectedOption(null);
      setIsAnswerCorrect(null);
      setShowExplanation(false);
    } else {
      setCompleted(true);
    }
  };

  const generatePDF = () => {
    const doc = new jsPDF();
    doc.text('MCQ Practice - 1', 10, 10);
    doc.text(`Score: ${score}`, 10, 20);

    const data = questions.map((q, index) => [
      `Q${index + 1}: ${q.question_text}`,
      `Correct Answer: ${q.answer}`,
      `Your Answer: ${selectedOptions[index] || 'Not Answered'}`,
    ]);

    doc.autoTable({
      head: [['Question', 'Correct Answer', 'Your Answer']],
      body: data
    });

    doc.save('test_results.pdf');
  };

  if (loading) return <div className="test-loader-container">
  <Oval type="ThreeDots" color="#ffffff" height={80} width={80} />
  <p>Your test is loading, please wait...</p>
</div>;
  if (error) return <div>Error: {error}</div>;

  const handleCompleteTest = () => {
    navigate('/score', { state: { score, questions, selectedOptions } });
  };

  return (
    <div className="test-container">
      <div className="header">
        <h1>MCQ Practice - 1</h1>
        <div className="score">
          Score: {score}
        </div>
      </div>
      {questions.length > 0 && (
        <>
          <div className="question-section">
            <h2>Question {currentQuestion + 1}</h2>
            <p>{questions[currentQuestion].question_text}</p>
          </div>
          <div className="options-section">
            {questions[currentQuestion].options.map((option, index) => (
              <label key={index} className="option-label">
                <input
                  type="radio"
                  name="option"
                  id={`option-${index}`}
                  className="option-input"
                  onClick={() => handleOptionClick(option)}
                  checked={selectedOption === option}
                  disabled={isAnswerCorrect !== null}
                />
                <div className={`option-button ${selectedOption === option ? (isAnswerCorrect === null ? 'selected' : (isAnswerCorrect ? 'correct' : 'incorrect')) : ''}`}>
                  <span>{option}</span>
                  {isAnswerCorrect !== null && (
                    <>
                      {option.charAt(0) === questions[currentQuestion].answer.charAt(0) && (
                        <span className="result correct">
                          <TiTick size={20} />
                        </span>
                      )}
                      {selectedOption === option && !isAnswerCorrect && (
                        <span className="result incorrect">
                          ❌
                        </span>
                      )}
                    </>
                  )}
                </div>
              </label>
            ))}
          </div>
          <div className="action-section">
            {isAnswerCorrect === null ? (
              <button className="next-button" onClick={handleNextClick} disabled={selectedOption === null}>
                Submit
              </button>
            ) : (
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <button className="show-explanation-button" onClick={handleShowExplanationClick}>
                  Show Explanation
                </button>
                {currentQuestion < questions.length - 1 && (
                  <button className="next-question-button" onClick={handleNextQuestion}>
                    Next Question
                  </button>
                )}
                {currentQuestion >= questions.length - 1 && (
                  <button className="complete-test-button" onClick={handleCompleteTest}>
                    Complete Test
                  </button>
                )}
              </div>
            )}
          </div>
          {showExplanation && (
            <div className="explanation-section">
              <p>{questions[currentQuestion].explanation}</p>
              {currentQuestion >= questions.length - 1 && <p>End of Test</p>}
            </div>
          )}
          {/* {completed && (
            <div className="download-section">
              <button className="download-pdf-button" onClick={generatePDF}>
                Download PDF
              </button>
            </div>
          )} */}
        </>
      )}
    </div>
  );
};

export default AptitudeTestPage;
