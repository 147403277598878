import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './components/Home';
import WeeklyTest from './components/WeeklyTest';
import MCQ from './components/MCQ';
import Materials from './components/Materials';
import SubjectTestPage from './components/SubjectTestPage';
import Plans from './components/Plans';
import BooksPage from './components/BooksPage';
import MCQUnitPage from './components/MCQUnitPage';
import TestPage from './components/TestPage';
import AptitudeTestPage from './components/AptitudeTestPage';
import { TestProvider } from './components/TestContext';
import { UserProvider } from './components/UserContext';
import WeeklyTestPage from './components/WeeklyTestPage';
import TermsAndConditions from './components/Terms&Conditions';
import { GoogleOAuthProvider } from '@react-oauth/google';
import Login from './components/Login';
import PrivacyPolicy from './components/PrivachPolicy';
import RefundPolicy from './components/RefundPolicy';
import NavbarDown from './components/NavbarDown';
import HelpPage  from './components/HelpPage';
import PaymentSuccessPage from './components/PaymentSuccessPage';
import PaymentFailurePage from './components/PaymentFailurePage';
import ScorePage from './components/ScorePage';
import ScrollToTop from './components/ScrollToTop';
// import TamilPdf from './components/TamilPdf';
import usePageTracking from './hooks/usePageTracking';





function App() {
  usePageTracking();  
  return (
    <GoogleOAuthProvider clientId="402163496970-qhqqclg0u2s6g2hro85fie1cnli4n8cv.apps.googleusercontent.com">
    <TestProvider>
      <UserProvider>
      <ScrollToTop />
      <Navbar />
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<Home />} />
        {/* <Route path="/tamil" element={<TamilPdf />} /> */}
        <Route exact path="/weekly-test" element={<WeeklyTest />} />
        <Route exact path="/mcq" element={<MCQ />} />
        <Route exact path="/materials" element={<Materials />} />
        <Route exact path="/plans" element={<Plans />} />
        <Route exact path="/books/:grade" element={<BooksPage />} />
        <Route exact path="/mcq/:topic" element={<SubjectTestPage />} />
        <Route exact path="/mcq/:topic/:stdd" element={<MCQUnitPage />} />
        <Route exact path="/testPage" element={<TestPage/>}/>
        <Route exact path="/aptitude/testpage" element={<AptitudeTestPage/>}/>
        <Route exact path="/weeklyTest/testpage" element={<WeeklyTestPage/>}/>
        <Route exact path="/terms-and-conditions" element={<TermsAndConditions/>}/>
        <Route exact path="/privacy-policy" element={<PrivacyPolicy/>}/>
        <Route exact path="/refund-policy" element={<RefundPolicy/>}/>
        <Route exact path="/help" element={<HelpPage />} />
        <Route path="/score" element={<ScorePage />}/>
        <Route exact path="/payment/success" element={<PaymentSuccessPage />} />
        <Route exact path="/payment/failure" element={<PaymentFailurePage />} />
        
      </Routes>
      <NavbarDown/>
    </UserProvider>
    </TestProvider>
    </GoogleOAuthProvider>
  );
}

export default App;