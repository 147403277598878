import React, {useState} from 'react';
import { useParams, useNavigate} from 'react-router-dom';
import { useTest } from '../TestContext';
import { useUser } from '../UserContext';
import LoginNotificationModal from '../LoginNotificationModal';
import CountNotificationModal from '../CountNotificationModal';
import { logEvent } from '../../analytics';
import './index.css';

const unitWiseContent = {
  6: {
    TermOne: [{ name: "UNIT-1", unit: "termOne", std: "sixthTamil" }, { name: "UNIT-2", unit: "termOneUnitTwo", std: "sixthTamil" }, { name: "UNIT-3", unit: "termOneUnitThree", std: "sixthTamil" }],
    TermTwo: [{ name: "UNIT-1", unit: "termTwoUnitOne", std: "sixthTamil" }, { name: "UNIT-2", unit: "termTwoUnitTwo", std: "sixthTamil" }, { name: "UNIT-3", unit: "termTwoUnitThree", std: "sixthTamil" }],
    TermThree: [{ name: "UNIT-1", unit: "termThreeUnitOne", std: "sixthTamil" }, { name: "UNIT-2", unit: "termThreeUnitTwo", std: "sixthTamil" }, { name: "UNIT-3", unit: "termThreeUnitThree", std: "sixthTamil" }]
  },
  7: {
    TermOne: [{ name: "UNIT-1", unit: "termOneUnitOne", std: "seventhTamil" }, { name: "UNIT-2", unit: "termOneUnitTwo", std: "seventhTamil" }, { name: "UNIT-3", unit: "termOneUnitThree", std: "seventhTamil" }],
    TermTwo: [{ name: "UNIT-1", unit: "termTwoUnitOne", std: "seventhTamil" }, { name: "UNIT-2", unit: "termTwoUnitTwo", std: "seventhTamil" }, { name: "UNIT-3", unit: "termTwoUnitThree", std: "seventhTamil" }],
    TermThree: [{ name: "UNIT-1", unit: "termThreeUnitOne", std: "seventhTamil" }, { name: "UNIT-2", unit: "termThreeUnitTwo", std: "seventhTamil" }, { name: "UNIT-3", unit: "termThreeUnitThree", std: "seventhTamil" }]
  },
  8: {
    TermOne: [{ name: "UNIT-1", unit: "termOneUnitOne", std: "eightTamil" }, { name: "UNIT-2", unit: "termOneUnitTwo", std: "eightTamil" }, { name: "UNIT-3", unit: "termOneUnitThree", std: "eightTamil" }],
    TermTwo: [{ name: "UNIT-1", unit: "termTwoUnitOne", std: "eightTamil" }, { name: "UNIT-2", unit: "termTwoUnitTwo", std: "eightTamil" }, { name: "UNIT-3", unit: "termTwoUnitThree", std: "eightTamil" }],
    TermThree: [{ name: "UNIT-1", unit: "termThreeUnitOne", std: "eightTamil" }, { name: "UNIT-2", unit: "termThreeUnitTwo", std: "eightTamil" }, { name: "UNIT-3", unit: "termThreeUnitThree", std: "eightTamil" }]
  },
  9: [
    { name: "UNIT-1", unit: "termOneUnitOne", std: "ninthTamil" }, { name: "UNIT-2", unit: "termOneUnitTwo", std: "ninthTamil" }, { name: "UNIT-3", unit: "termOneUnitThree", std: "ninthTamil" },
    { name: "UNIT-4", unit: "termTwoUnitOne", std: "ninthTamil" }, { name: "UNIT-5", unit: "termTwoUnitTwo", std: "ninthTamil" }, { name: "UNIT-6", unit: "termTwoUnitThree", std: "ninthTamil" },
    { name: "UNIT-7", unit: "termThreeUnitOne", std: "ninthTamil" }, { name: "UNIT-8", unit: "termThreeUnitTwo", std: "ninthTamil" }, { name: "UNIT-9", unit: "termThreeUnitThree", std: "ninthTamil" },
  ],
  10: [
    { name: "UNIT-1", unit: "unitOne", std: "Tenth" }, { name: "UNIT-2", unit: "unitTwo", std: "Tenth" }, { name: "UNIT-3", unit: "unitThree", std: "Tenth" },
    { name: "UNIT-4", unit: "unitFour", std: "Tenth" }, { name: "UNIT-5", unit: "unitFive", std: "Tenth" }, { name: "UNIT-6", unit: "unitSix", std: "Tenth" },
    { name: "UNIT-7", unit: "unitSeven", std: "Tenth" }, { name: "UNIT-8", unit: "unitEight", std: "Tenth" }, { name: "UNIT-9", unit: "unitNine", std: "Tenth" },
  ],

  generalScience:{ topicsCovered: [
    "Nature of Universe",
    "Measurement of Physical Quantities",
    "General Scientific Laws in Motion",
    "Force, Pressure and Energy",
    "Everyday application of the basic principles of Mechanics",
    "Electricity, Magnetism, Light, Sound, Heat and Nuclear Physics in our daily life",
    "Elements and Compounds",
    "Acids, Bases, Salts",
    "Petroleum Products",
    "Fertilizers",
    "Pesticides",
    "Metallurgy",
    "Food Adulterants",
    "Main Concepts of Life Science",
    "Classification of living organisms",
    "Evolution",
    "Genetics",
    "Physiology",
    "Nutrition",
    "Health and Hygiene",
    "Human diseases",
    "Environmental Science"],  unit: "generalScience", std: "generalStudies" },
  currentEvents:{ topicsCovered: [
    "The most recent event calendar",
    "National symbols",
    "State profiles",
    "Famous people and places in the news",
    "Sports Authors and Books",
    "Government Welfare Schemes, Political Parties, and the Political System in Tamil Nadu and India",
    "Science and technology’s most recent breakthroughs",
    "Landmarks on the Map",
    "Current Socioeconomic Concerns"],  unit: "currentEvents", std: "generalStudies"  },
  history:{ topicsCovered: [
    "Indus Valley Civilization",
    "Guptas, Delhi Sultans, Mughals, and Marathas",
    "South Indian History",
    "Characteristics of Indian Culture",
    "Unity in Diversity",
    "India as a Secular State"
  ],  unit: "history", std: "generalStudies"  },
  IndianPolity:{ topicsCovered: [
    "India’s Constitution",
    "Constitutional Highlights",
    "Fundamental Duties and Fundamental Rights",
    "State Policy Directive Principles",
    "Union Executive, Union Legislative Assembly",
    "State Governor, State Legislature",
    "Local Authorities",
    "The Panchayat Raj system",
    "The Federalism Spirit",
    "Relationships between the Center and the State",
    "Elections – India’s Judiciary – Rule of Law",
    "Corruption in government",
    "Anti-corruption policies",
    "Lokayukta and Lokpal",
    "Access to Information",
    "Women’s Empowerment",
    "Consumer Protection Forums",
    "Human Rights Charter"
  ],  unit: "IndianPolity", std: "generalStudies"  },
  geography:{ topicsCovered: ["Earth Location",
  "Physical Features - Monsoon, rainfall, weather and climate",
  "Water resources – Rivers",
  "Soil, Minerals and Natural resources",
  "Forest and Wildlife",
  "Agriculture pattern",
  "Transport",
  "Communication",
  "Population density and distribution in Tamil Nadu and India",
  "Calamities",
  "Disaster Management",
  "Environment",
  "Climate change"],  unit: "geography", std: "generalStudies"  },
  economics:{ topicsCovered: ["Nature of Indian economy",
  "Five-year plan models – an assessment",
  "Planning Commission",
  "Niti Ayog",
  "Sources of revenue – Reserve Bank of India",
  "Finance Commission",
  "Resource sharing between Union and State Governments",
  "Goods and Services Tax",
  "Economic Trends – Employment Generation, Land Reforms and Agriculture",
  "Application of Science and Technology in Agriculture",
  "Industrial growth",
  "Rural Welfare programmes",
  "Social Problems",
  "Population, Education, Health, Employment, Poverty"],  unit: "economics", std: "generalStudies"  },
  indianNationalMovement:{ topicsCovered: ["National Renaissance",
  "The early uprising against British Rule",
  "Indian National Congress",
  "Emergence of Leaders",
  "Different modes of Agitation of Tamil Nadu and movements"],  unit: "indianNationalMovement", std: "generalStudies"  },
  DevelopmentAdministrationinTN :{ topicsCovered: [ "Social Justice and Social Harmony as the Cornerstones of Socio-Economic Development",
    "Education and Health Systems in Tamil Nadu",
    "Geography of Tamil Nadu and its impact on Economic growth"],  unit: "Development Administration in TN", std: "generalStudies"  },
  HistoryCultureHeritageandSocioPoliticalMovementsofTN :{ topicsCovered: [ "History of Tamil Society, related Archaeological Discoveries, Tamil Literature from Sangam age till contemporary times",
    "Thirukkural - Significance as a Secular Literature, Relevance to Everyday Life, Impact of Thirukkural on Humanity, Thirukkural and Universal Values – Equality, Humanism etc, Relevance to Socio – Politico-Economic affairs, Philosophical content in Thirukkural",
    "Role of Tamil Nadu in the freedom struggle",
    "Early agitations against British Rule",
    "Role of women in the freedom struggle",
    "Various Social reformers, Social reform movements and Social transformation of Tamil Nadu"
  ],  unit: "History, Culture, Heritage, and Socio-Political Movements of TN", std: "generalStudies"  }

};


function MCQUnitPage() {
  const { stdd } = useParams();
  const { nizhalUser } = useUser();
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showCountModal, setShowCountModal] = useState(false);
  const navigate = useNavigate();
  const { setStandard, setSubject, medium, setMedium } = useTest();
  const std = unitWiseContent[stdd];

  if (!std) {
    return <div>No data available for grade {stdd}</div>;
  }

  const handleCardClick = (stnd, sub) => {

    logEvent('MCQ', 'Clicked Topic', { stnd, sub });

  
    if (!nizhalUser || !nizhalUser.userId) {
      setShowLoginModal(true);
      return;
    }
  
    if (nizhalUser.userPlan === "premium" || nizhalUser.userPlan === "basic") {
      setStandard(stnd);
      setSubject(sub);
      navigate('/testpage');
      return; // Exit the function here
    }
  
    if (nizhalUser.userCount > 3) {
      setShowCountModal(true);
      return;
    }
  
    setStandard(stnd);
    setSubject(sub);
    navigate('/testpage');
  };
  

  const renderBooksTable = (termUnits, termName) => (
    <div key={termName} className='whole-renderBook-table'>
      <h2 >{termName}</h2>
      <div className="mcq-unit-materials-grid">
        {termUnits.map((eachUnit) => (
          <div
            key={eachUnit.unit}
            className="mcq-unit-material-card"
            onClick={() => handleCardClick(eachUnit.std, eachUnit.unit)}
          >
            <h2 className="mcq-unit-material-grade">{eachUnit.name}</h2>
          </div>
        ))}
      </div>
    </div>
  );

  const renderTopics = () => (
    <div className="mcq-unit-topics-container">
      <button className="mcq-unit-start-test-button" onClick={() => handleCardClick(std.std, std.unit)}>
        Start Test
      </button>
      <h2>Topics Covered</h2>
      <div className='mcq-unit-unique-materials-grid'>
        {std.topicsCovered && std.topicsCovered.map((topic, index) => (
          <div key={index} className="mcq-unit-unique-material-card">{topic}</div>
        ))}
      </div>
    </div>
  );


  const handleMediumChange = (e) => {
   
    setMedium(e.target.value);
  };
  return (
    <>
    <div className="mcq-unit-books-container">
      {(!(/^\d+$/.test(stdd)))? <h1 className="mcq-unit-books-title">{stdd.toLocaleUpperCase()}</h1>: <h1 className="mcq-unit-books-title">{stdd}th Text Books</h1>}
      <select 
          className="mcq-unit-books-medium-select" 
          value={medium} 
          onChange={handleMediumChange}
        >
          <option value="TM">Tamil Medium</option>
          <option value="EM">English Medium</option>
        </select>
      {Array.isArray(std) ? (
        renderBooksTable(std, "Units")
      ) : (
        <>
          {std.TermOne && renderBooksTable(std.TermOne, "Term I")}
          {std.TermTwo && renderBooksTable(std.TermTwo, "Term II")}
          {std.TermThree && renderBooksTable(std.TermThree, "Term III")}
        </>
      )}
      {!(/^\d+$/.test(stdd)) && renderTopics()}
    </div>
    {showLoginModal && (
      <LoginNotificationModal onClose={() => setShowLoginModal(false)} />
    )}
    {showCountModal && (
      <CountNotificationModal onClose={() => setShowCountModal(false)} />
    )}
    </>
  );
}

export default MCQUnitPage;