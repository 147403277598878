import React, {useState} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTest } from '../TestContext';
import { useUser } from '../UserContext';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import axios from 'axios';
import Select from 'react-select'
import { FaRegArrowAltCircleRight } from "react-icons/fa";
import LoginNotificationModal from '../LoginNotificationModal';
import CountNotificationModal from '../CountNotificationModal';
import TamilFullTestModal from '../TamilFullTestModal';
import {Oval} from 'react-loader-spinner';
import './index.css';
import { FaTimes } from 'react-icons/fa';
// import Modal from 'react-modal';

// Modal.setAppElement('#root');

const list_a = ['6', '7', '8', '9', '10'];
const tamilGrammerList = [{name:'அடைமொழி சான்றோர்',dbClName: "adaimozhiSandroer"},{name:'அடைமொழி பாடல்',dbClName: "adaimozhiNoolgal"}]
const tamilFullStandardWiseList = [{name:'ஆறாம் வகுப்பு முழு தேர்வு',dbClName: "sixthTamilFull"},{name:'ஏழாம் வகுப்பு முழுத் தேர்வு',dbClName: "seventhTamilFull"},{name:'எட்டாம் வகுப்பு முழுத் தேர்வு',dbClName: "eighthTamilFull"},{name:'ஒன்பதாம் வகுப்பு முழுத் தேர்வு',dbClName: "ninthTamilFull"},{name:'பத்தாம் வகுப்பு முழுத் தேர்வு',dbClName: "tenthTamilFull"}]

const generalStudies = [
  { name: "General Science", dbClName: "generalScience" },
  { name: "Current Events", dbClName: "currentEvents" },
  { name: "Geography", dbClName: "geography" },
  { name: "History", dbClName: "history" },
  { name: "Indian Polity", dbClName: "IndianPolity" },
  { name: "Economy", dbClName: "economics" },
  { name: "Indian National Movement", dbClName: "indianNationalMovement" },
  { name: "History, Culture, Socio-Political Movements in Tamil Nadu", dbClName: "HistoryCultureHeritageandSocioPoliticalMovementsofTN" },
  { name: "Development and Administration in Tamil Nadu", dbClName: "DevelopmentAdministrationinTN" }
];
const aptitude = [
  // { name: "Simplification", topicName: "simplification" },
  // { name: "Percentage", topicName: "percentage" },
  { name: "HCF and LCM", topicName: "HCF and LCM" },
  // { name: "Lowest Common Multiple (LCM)", topicName: "lcm" },
  { name: "Ratio and Proportion", topicName: "ratio and proportion" },
  // { name: "Simple Interest", topicName: "si" },
  // { name: "Compound Interest", topicName: "ci" },
  // { name: "Area", topicName: "area" },
  // { name: "Volume", topicName: "volume" },
  // { name: "Time and Work", topicName: "timeandwork" },
  { name: "Time and Work", topicName: "Work and Time" },
  { name: "Arithmetic Progression", topicName: "arithmetic progression" },
  { name: "Geometry", topicName: "geometry" },

  { name: "Probability", topicName: "probability" },
  { name: "Statistics", topicName: "statistics" },
  { name: "Speed and Distance", topicName: "Speed and Distance" },

  



  // { name: "Logical Reasoning", topicName: "logicalreasoning" },
  // { name: "Puzzles", topicName: "puzzles" },
  // { name: "Dice", topicName: "dice" },
  // { name: "Visual Reasoning", topicName: "vr" },
  // { name: "Alpha Numeric Reasoning", topicName: "anr" },
  // { name: "Number Series", topicName: "ns" },
];

const SubjectTestPage = () => {
  const { topic } = useTest();
  const { nizhalUser, setNizhalUser } = useUser();
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showCountModal, setShowCountModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedGrade, setSelectedGrade] = useState(null);
  // const [isTamilModalVisible, setIsTamilModalVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  // const [selectedGrade, setSelectedGrade] = useState(null);

  const navigate = useNavigate();
  const { stnd,setSubject,setStandard, subject, medium ,noOfQuestions,setNoOfQuestions} = useTest();


  let itemsToDisplay = [];


  

  const AptitudeTopics = ({ list }) => {
    const [selected, setSelected] = useState(list.map((_, index) => index < 3)); // Default first 3 options to true
    const [selectedOptions, setSelectedOption] = useState(10);
    // const [selectedLanguage, setSelectedLanguage] = useState('commonAptitudeEM'); 
    const { setAptitudeResponse, medium,setMedium } = useTest(); // Destructure setAptitudeResponse from context
    const navigate = useNavigate(); // 

    const handleCardClick = (index) => {
        const newSelected = [...selected];
        newSelected[index] = !newSelected[index];
        setSelected(newSelected);
    };

    const handleMediumChange = (e) => {
      setMedium(e.target.value);
      
    };

    const handleSelectChange = (option) => {
        setSelectedOption(option.value);
    };

    const handleCloseLoginModal = () => {
      setShowLoginModal(false);
    };
  

    const handleButtonClick = async () => {
      const selectedTopics = list.filter((_, index) => selected[index]).map(topic => topic.topicName);
      
      if (!nizhalUser || !nizhalUser.userId) {
        setShowLoginModal(true);
        return;
      }
    
      // No check for userCount for basic and premium users
      
      if (nizhalUser.userPlan !== "premium" && nizhalUser.userPlan !== "basic") {
        if (nizhalUser.userCount > 3) {
          setShowCountModal(true);
          return;
        }
      }
    
      try {
        setLoading(true);
        const response = await axios.post('https://2mn4dxxw3hj2yrhqzbsxdyirva0uksoy.lambda-url.ap-south-1.on.aws/api/aptitude', { 
          userId: nizhalUser.userId, 
          selectedTopics, 
          selectedOptions, 
          medium 
        });           
              
        setAptitudeResponse(response.data.questions);
        const userData = response.data.user;

        const storedUser = {
          userName: userData.displayName,
          userImageUrl: userData.profileImageUrl,
          userId: userData.uniqueId,
          userEmail: userData.email,
          userPlan: userData.plan, 
          userCount: userData.count,
        };
  
        setNizhalUser(storedUser); 
        
        navigate(`/aptitude/testpage`); 
      } catch (error) {
        console.error('Error making API call', error);
      }
    };
    

    const options = [
        { value: 5, label: '5 Questions' },
        { value: 10, label: '10 Questions' },
        { value: 15, label: '15 Questions' },
        { value: 20, label: '20 Questions' },
        { value: 30, label: '30 Questions' },
        { value: 40, label: '40 Questions' }
    ];

    return (
      <>
        <div className="subject-section">
            <h2>Aptitude Topics</h2>   
            <div> 
              <div className="input-container-aptitude-n-a">
                    <div className="select-container-aptitude-n-a">
                        <label style={{ textAlign: 'center' }} htmlFor="question-select" className="select-label">Select Number of Questions:</label>
                        <Select
                             className="select-width-container"                        
                            id="question-select"
                            options={options}
                            value={options.find(option => option.value === selectedOptions)}
                            onChange={handleSelectChange}
                        />
                    </div>
                    <select 
          className="mcq-unit-books-medium-select" 
          value={
            medium
          } 
          onChange={handleMediumChange}
        >
          <option value="TM">Tamil Medium</option>
          <option value="EM">English Medium</option>
        </select>
        <button className="custom-button" onClick={handleButtonClick}>
                        Start Now
                    </button>
                    
                    <h3>Select the topics you want to practice </h3>
                    <h3>Click on the card to select Topics</h3>
            </div>
            
                <ul className="subject-test-page-aptitude">
                    {list.map((topic, index) => (
                        <li
                            key={index}
                            className={`grade-card-aptitude ${selected[index] ? 'selected' : ''}`}
                            onClick={() => handleCardClick(index)}
                        >
                            {selected[index] ? (
                              <FaCheckCircle className="icon green" />
                            ) : (
                              <FaTimesCircle color="red" className="icon" />
                            )}
                            <span>{topic.name}</span>
                        </li>
                    ))}
                </ul>
                
            </div>
        </div>
        {showLoginModal && (
          <LoginNotificationModal onClose={handleCloseLoginModal} />
        )}
        {showCountModal && (
          <CountNotificationModal onClose={() => setShowCountModal(false)} />
        )}
        {loading && 
          <div className="test-loader-container">
            <Oval type="ThreeDots" color="#ffffff" height={80} width={80} />
            <p>Your test is loading, please wait...</p>
          </div>
        }
        </>
    );
};

const GeneralStudiesTopics = ({ list }) => {
  return (
    <div className="subject-section">
      <h2>General Studies Topics</h2>
      <div className="subject-test-page">
        {list.map((topic, index) => (
          <Link
            to={`/mcq/General%20Studies/${topic.dbClName}`}
            key={index}
            className="card card-gs-t"
          >
            <div className="grade-card-gs-t">
              {topic.name}
              <div>
                
              </div>
              <button> SELECT<FaRegArrowAltCircleRight /></button>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};


const TamilTopics = ({ list }) => {

  const options = [
    { value: 5, label: '5 Questions' },
    { value: 10, label: '10 Questions' },
    { value: 15, label: '15 Questions' },
    { value: 20, label: '20 Questions' },
    { value: 30, label: '30 Questions' },
    { value: 40, label: '40 Questions' },
  ];

  const handleClickStandardWiseFullTest = (grade) => {
    setSelectedGrade(grade);
    setIsModalVisible(true);
  };

  const handleSelectChangeTamil = (selectedOption) => {
    // Ensure that modal visibility remains true when selecting an option
    setNoOfQuestions(selectedOption.value);
    setIsModalVisible(true); // Keep modal visible
  };

  const handleStartTest = () => {
    setStandard('TamilSubject');
    setSubject(selectedGrade.dbClName);
    navigate('/testpage');
  };

  const handleClick = (grade) => {
    setStandard('grammerTamil');
    setSubject(grade.dbClName);
    navigate('/testpage');
  };

return (
  <div className="subject-section">
    <h2>தமிழ்</h2>
    <h3>Select standard (Topic)</h3>
    <h3 className='tamilUnitTestHeading'>அலகு(UNIT) வாரியான தேர்வு</h3>
    <h3 className='tamilFullTestHeading-design'>*************************</h3>
    <div className="subject-test-page">
      {list.map((grade, index) => (
        <Link
          to={`/mcq/Tamil/${grade.dbClName}`}
          key={index}
          className="card card-tamil"
        >
          <div className="grade-card-tamil">
            {grade.name}
            <button>SELECT <FaRegArrowAltCircleRight /></button>
          </div>
        </Link>
      ))}
    </div>

    <div className="subject-test-page">
      {tamilGrammerList.map((grade, index) => (
        <div
          key={index}
          className="card card-tamil"
          onClick={() => handleClick(grade)}
        >
          <div className="grade-card-tamil">
            {grade.name}
            <button>SELECT <FaRegArrowAltCircleRight /></button>
          </div>
        </div>
      ))}
    </div>

      <h3 className='tamilFullTestHeading'>தமிழ் பாடம் முழு தேர்வு</h3>
      <h3 className='tamilFullTestHeading-design'>*************************</h3>
    <div className="subject-test-page">
      {tamilFullStandardWiseList.map((grade, index) => (
        <div
          key={index}
          className="card card-tamil"
          onClick={() => handleClickStandardWiseFullTest(grade)}
        >
          <div className="grade-card-tamil">
            {grade.name}
            <button>SELECT <FaRegArrowAltCircleRight /></button>
          </div>
        </div>
      ))}

      {isModalVisible && (
          <div className="modal">
            <div className="modal-content">
              <div className="select-container-aptitude-n-a">
                <label htmlFor="question-select" className="select-label">Select Number of Questions:</label>
                <Select
                  className="select-width-container"
                  id="question-select"
                  options={options}
                  value={options.find(option => option.value === noOfQuestions)}
                  onChange={handleSelectChangeTamil}
                />
              </div>
              <button className="tamil-start-button" onClick={handleStartTest}>Start Test</button>
              <button style={{border: 'none', marginLeft: '10px', marginTop: '10px', backgroundColor: 'red', color: 'white', borderRadius: '5px', padding: '10px 20px',cursor: 'pointer' }} onClick={() => setIsModalVisible(false)}>Cancel</button>
            </div>
          </div>
        )}

    </div>
  </div>
);
};


  
  if (topic === 'Tamil') {
    itemsToDisplay = list_a.map(grade => ({ name: `${grade}th`, dbClName: grade }));
    return <TamilTopics list={itemsToDisplay} />;
  } else if (topic === 'Aptitude') {
    itemsToDisplay = aptitude;
    return <AptitudeTopics list={itemsToDisplay} />;
  } else {
    itemsToDisplay = generalStudies;
    return <GeneralStudiesTopics list={itemsToDisplay} />;
  }
};

export default SubjectTestPage;
