import React, { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { logEvent } from '../../analytics';
import { useUser } from '../UserContext';
import './index.css';
import { useTest } from '../TestContext';
import GenerateStyledPdf from '../TamilPdf';
import { useNavigate } from 'react-router-dom';

const ScorePage = () => {
  const location = useLocation();
  const { nizhalUser } = useUser();
  const { score, questions, selectedOptions } = location.state || { score: 0, questions: [], selectedOptions: [] };
  const navigate = useNavigate();
  const { medium } = useTest();
  const [generateTamil, setGenerateTamil] = useState(false);
  // const generateEnglishPDF = () => {
  //   logEvent('TEST PAGE', 'Clicked Download PDF', nizhalUser.userEmail);

  //   const doc = new jsPDF();
  //   doc.text('MCQ Practice - 1', 10, 10);
  //   doc.text(`Score: ${score}`, 10, 20);

  //   const data = questions.map((q, index) => [
  //     `Q${index + 1}: ${q.question_text}`,
  //     `Correct Answer: ${q.answer}`,
  //     `Your Answer: ${selectedOptions[index] || 'Not Answered'}`,
  //   ]);

  //   doc.autoTable({
  //     head: [['Question', 'Correct Answer', 'Your Answer']],
  //     body: data
  //   });

  //   doc.save('test_results.pdf');
  // };

  const generateEnglishPDF = () => {
    logEvent('TEST PAGE', 'Clicked Download PDF', nizhalUser.userEmail);
  
    const doc = new jsPDF();
  
    // Add logo at the top-left corner (replace 'logo.png' with the path to your image)
    // doc.addImage('\assets\logoTNPSC.jpg', 'PNG', 10, 10, 30, 30); // Use 'JPG' if it's a .jpg image
    doc.addImage('/assets/logoTNPSC.jpg', 'JPG', 10, 10, 30, 30);

    // Centered title and tagline
    doc.setFontSize(20);
    doc.text('Nizhal TNPSC', doc.internal.pageSize.getWidth() / 2, 20, { align: 'center' });
    doc.setFontSize(12);
    doc.text('Your Gateway to Success in Government Exams', doc.internal.pageSize.getWidth() / 2, 30, { align: 'center' });
  
    // Add "Education Kavithai" in two lines below the tagline
    // doc.setFontSize(14);
    // doc.text('Education Kavithai Line 1', doc.internal.pageSize.getWidth() / 2, 40, { align: 'center' });
    // doc.text('Education Kavithai Line 2', doc.internal.pageSize.getWidth() / 2, 50, { align: 'center' });
  
    // Add name, topic, date, and total number of questions on the left side
    doc.setFontSize(10);
    doc.text(`Name: ${nizhalUser.userName}`, 170, 50);
    // doc.text(`Topic: ${topic}`, 10, 70);
    doc.text(`Date: ${new Date().toLocaleDateString()}`, 170, 55);
    doc.text(`Total Questions: ${questions.length}`, 170, 60);
  
    // Add the score and styled text for MCQ Practice
    // doc.setFontSize(16);
    // doc.text('MCQ Practice - 1', 10, 110);
    // doc.setFontSize(12);
    doc.text(`Score: ${score}`, 10, 70);
  
    // Prepare the data for the table
    const data = questions.map((q, index) => [
      `Q${index + 1}: ${q.question_text}`,
      `Correct Answer: ${q.answer}`,
      `Your Answer: ${selectedOptions[index] || 'Not Answered'}`,
    ]);
  
    // Add the table with styling
    doc.autoTable({
      head: [['Question', 'Correct Answer', 'Your Answer']],
      body: data,
      startY: 80,
      theme: 'striped',  // Optional: change the theme for the table
      styles: { fontSize: 10 }
    });
  
    // Add a watermark
    // doc.setTextColor(240);
    // doc.setFontSize(40);
    // doc.text('Nizhal TNPSC', doc.internal.pageSize.getWidth() / 2, doc.internal.pageSize.getHeight() / 2, {
    //   align: 'center',
    //   angle: 45,
    //   opacity: 0.05,
    // });
  
    // Save the PDF with the user's name and topic
    const pdfName = `${nizhalUser.userName}.pdf`;
    doc.save(pdfName);
  };
    

  const handleGenerateTamilPDF = () => {
    logEvent('TEST PAGE', 'Clicked Download PDF', nizhalUser.userEmail);
    setGenerateTamil(true);
  };

  const attemptedQuestions = questions.filter((q, index) => q.answer === selectedOptions[index]).length;

  return (
    <div className="score-page">
      <h2>Test Completed</h2>
      <div className="score-section">
        <p>Score: <span className="score-value">{score}</span></p>
        <div className="score-ratio">
          <div className="score-bar">
            <div className="score-fill" style={{ width: `${(score / questions.length) * 100}%` }}></div>
          </div>
          <p className="score-percentage">{((score / questions.length) * 100).toFixed(2)}%</p>
        </div>
      </div>
      <ul className="test-summary">
        <li className="total-questions">
          <span className="bullet blue"></span>
          Total Questions: {questions.length}
        </li>
        <li className="attempted-questions">
          <span className="bullet yellow"></span>
          Questions Correctly Answered: {attemptedQuestions}
        </li>
        <li className="not-attempted-questions">
          <span className="bullet red"></span>
          Wrong Answers: {questions.length - attemptedQuestions}
          Questions Not Correctly Answered: {questions.length - attemptedQuestions}
        </li>
      </ul>
      <div className='tamil-english-pdf-download-button'>
      {medium === "EM" && (
        <button className="download-pdf-button" onClick={generateEnglishPDF}>
          Download PDF
        </button>
      )}
      {medium === "TM" && (
        <button className="download-pdf-button" onClick={handleGenerateTamilPDF}>
          Download Tamil PDF
        </button>
      )}
      <button
      className="back-button"
      onClick={() => navigate('/mcq')} // Use navigate instead of Navigate
    >
      Back
    </button>
      </div>
      {generateTamil && questions.length > 0 && (
        <GenerateStyledPdf 
          questions={questions} 
          selectedOptions={selectedOptions} 
          score={score}
          onGeneratePdf={generateTamil}
        />
      )}
    </div>

  ); 
};

export default ScorePage;
